
import { Provide, Prop } from 'vue-property-decorator';
import { BaseObject, Connection, ObjectDefinition, Waypoint } from '@/components/automation/flows/definitions/FlowDefinitions';
import { BeforeCreate, Created, Mounted } from '@/decorators/LifeCycle';
import { Options, Vue } from 'vue-class-component';
import ObjectConfig from '@/components/automation/flows/designer/ConfigComponents/ObjectConfig.vue';

type HelpText = {
    title: string;
    text: string;
    default: string;
    defaultTitle: string;
};

@Options({
    components: { ObjectConfig },
})
export default class ObjectConfigWindow extends Vue {
    @Prop() private object!: BaseObject;
    @Prop() private readonly!: boolean;

    protected description: string = '';
    protected loading: boolean = false;

    save() {
        this.$emit('save', {
            //@ts-ignore
            config: (this.$refs.configComponent as ObjectConfig).getConfig(),
            description: this.description,
        });
    }

    componentHelpText: HelpText = {
        title: '',
        text: '',
        default: '',
        defaultTitle: '',
    };

    async onHelpText(helpText: HelpText) {
        this.componentHelpText = helpText;
    }

    protected objectConfig: false | { [key: string]: any } = false;

    @Created
    async retrieveObjectConfig(): Promise<void> {
        this.loading = true;
        this.objectConfig = await this.object.remote().getConfigDefinition(this.object.config);
        this.description = this.object.description;
        console.log('objectConfig', this.objectConfig);
        this.loading = false;
    }

    async dependencyChanged(fieldName: any) {
        window.setTimeout(async () => {
            console.log('dependencyChanged', fieldName);
            //@ts-ignore
            this.objectConfig = await this.object.remote().getConfigDefinition((this.$refs.configComponent as ObjectConfig).getConfig());
            console.log('objectConfig reloaded', this.objectConfig);
        }, 1);
    }
}


import { Options, Vue } from 'vue-class-component';
import api from '@/shared/api';
import getAppDefinitions, { AppDefinitions } from '@/shared/apps';

const getLSCallData = (def: any) => {
    const LS = localStorage.getItem('debug-calldata');
    if (LS) {
        return JSON.parse(LS);
    }
    return def;
};
const setLSCallData = (data: any) => {
    localStorage.setItem('debug-calldata', JSON.stringify(data));
};

@Options({
    components: {},
    props: {},
})
export default class SprinklrApiDebugger extends Vue {
    protected installedApps = [];
    protected availableApps: AppDefinitions = {};

    protected ready = false;

    protected executing = false;

    protected lastResponse = { data: '', headers: '' };

    protected endpointPresets = {
        Bootstrap: [['GET', 'v1/bootstrap/resources?types=', 'Bootstrap resources']],
        Assets: [
            ['POST', 'v1/sam', 'Create asset'],
            ['GET', 'v1/sam/{asset id}', 'Read asset'],
            ['DELETE', 'v1/sam/{asset id}', 'Delete asset'],
        ],
    };

    protected callData = getLSCallData({
        app: 21,
        method: 'GET',
        endpoint: 'v1/sam/63d0f64c9e30210bd938928a',
        body: '',
    });

    async created() {
        this.availableApps = await getAppDefinitions();
        this.installedApps = (await api.getInstalledApps()).filter(
            (app: { authenticated: boolean }) => app.authenticated,
        );

        this.ready = true;
    }

    setPreset(preset: string[]) {
        this.callData.method = preset[0];
        this.callData.endpoint = preset[1];
    }

    get formattedResponseData() {
        if (this.lastResponse && this.lastResponse.data) {
            return JSON.stringify(this.lastResponse.data, null, '    ');
        }
        return '';
    }

    async execute() {
        this.executing = true;
        this.lastResponse = Object.assign(
            this.lastResponse,
            { data: null, headers: null },
            await api.executeSprinklrApi(this.callData),
        );
        (window as any).response = JSON.parse(JSON.stringify(this.lastResponse.data));
        this.executing = false;

        setLSCallData(this.callData);
    }
}

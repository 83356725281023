import { ref } from 'vue';
import session from '@/shared/session';

class Api {
    protected baseUrl = '';

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    async call(method: 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH', url: string, postData: any = null, headers: { [key: string]: string } | null = null) {
        try {
            const response = await fetch(this.baseUrl + '/' + url, {
                method: method,
                body: postData ? JSON.stringify(postData) : null,
                headers: Object.assign(
                    {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + session.getAccessToken(),
                        'X-BusinessUnit': session.getCurrentBusinessUnit(),
                    },
                    headers ?? {},
                ),
            });

            if ([200, 201, 204].indexOf(response.status) === -1) {
                throw new Error('Call returned with statuscode ' + response.status);
            }

            const data = await response.json();
            console.log('API ' + method + ':' + url, postData, data);
            return data;
        } catch (reason) {
            console.error('API ' + method + ':' + url, postData, reason);
            throw reason;
        }
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async post(url: string, postData: any = null, headers: { [key: string]: string } | null = null): Promise<any> {
        return this.call('POST', url, postData, headers);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async patch(url: string, postData: any): any {
        return this.call('PATCH', url, postData);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async get(url: string, headers: { [key: string]: string } | null = null): Promise<any> {
        return this.call('GET', url, null, headers);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async delete(url: string): any {
        return this.call('DELETE', url);
    }

    async authCheck(accessToken: string) {
        return this.get('auth/check', {
            Authorization: 'Bearer ' + accessToken,
        });
    }

    async authLogin(username: string, password: string) {
        return this.post('auth/login', {
            username,
            password,
        });
    }

    async authRefresh(refreshToken: string) {
        return this.post('auth/refresh', null, {
            Authorization: 'Bearer ' + refreshToken,
        });
    }

    async logout(refreshToken: string) {
        return this.post('auth/logout', {
            refresh_token: refreshToken,
        });
    }

    async getAppDefinitions() {
        return this.get('apps/defs');
    }

    async getInstalledApps() {
        return this.get('apps');
    }

    async authorizeApp(appKey: string, code: string, env: string) {
        return this.patch('apps/' + appKey, { code, env });
    }

    async connectAppToSystem(appId: number, systemId: string) {
        return this.patch('apps/' + appId, { systemId });
    }

    async installApp(app_name: string) {
        return this.post('apps', { app_name });
    }

    async removeApp(appId: number) {
        return this.delete('apps/' + appId);
    }

    async getAppSettings(appId: number) {
        return this.get('apps/' + appId);
    }

    async saveAppSettings(appId: number, settings: any) {
        return this.patch('apps/' + appId, { settings });
    }

    async getSprinklrUserGroups(appId: number) {
        return this.get('sprinklr-api/user-groups/' + appId);
    }

    async getSprinklrClients(appId: number) {
        return this.get('sprinklr-api/clients/' + appId);
    }

    async getSprinklrCustomProperties(appId: number, clientId: number) {
        return this.get('sprinklr-api/custom-asset-properties/' + appId + '/' + clientId);
    }

    async getSprinklrCampaigns(appId: number, clientId: number) {
        return this.get('sprinklr-api/campaigns/' + appId + '/' + clientId);
    }

    async getSprinklrPartnerAccounts(appId: number, clientId: number) {
        return this.get('sprinklr-api/partner-accounts/' + appId + '/' + clientId);
    }

    async getImageCropResizePreview(appId: number, fileName: string, fileBase64: string, actions: any) {
        return this.post('sprinklr-api/image-crop-resize-preview', {
            appId,
            fileName,
            fileBase64,
            actions,
        });
    }

    async getSprinklrRecentZipAssets(appId: number) {
        return this.get('sprinklr-api/recent-zip-assets/' + appId);
    }

    async getSprinklrAsset(appId: number, clientId: string, assetId: string) {
        return this.get('sprinklr-api/asset/' + appId + '/' + clientId + '/' + assetId);
    }

    async postAssetUnzipExtractZipAsset(appId: number, assetId: string, clientId: string, ruleSet: string) {
        return this.post('asset-unzip/extract-zip-asset', {
            appId,
            assetId,
            clientId,
            ruleSet,
        });
    }

    async postAssetUnzipUploadCsv(appId: number, assetId: string, clientId: string) {
        return this.post('asset-unzip/upload-csv', { appId, assetId, clientId });
    }

    async postAssetUnzipResetImport(appId: number, assetId: string, clientId: string) {
        return this.post('asset-unzip/reset-import', { appId, assetId, clientId });
    }

    async getAssetUnzipActiveImports() {
        return this.get('asset-unzip/active-imports');
    }

    async addFlow(name: string) {
        return this.post('flow/add', { name });
    }

    async addContent(type: string, name: string) {
        return this.post('content/add', { type, name });
    }

    async addBusinessUnit(name: string, parentId: string | null) {
        return this.post('businessunits', { name, parent: { id: parentId } });
    }

    async updateBusinessUnit(id: string, name: string, parent_id: string | null) {
        return this.patch(`businessunits/${id}`, { name, parent_id });
    }

    async deleteBusinessUnit(id: string) {
        return this.delete(`businessunits/${id}`);
    }

    async getBusinessUnits() {
        return this.get('businessunits');
    }

    async createUser(firstName: string, lastName: string, email: string, lastNamePrefix: string) {
        return this.post('users', { firstName, lastName, email, lastNamePrefix });
    }

    async updateUser(id: string, firstName: string, lastName: string, email: string, lastNamePrefix: string) {
        return this.patch(`users/${id}`, {
            id,
            firstName,
            lastName,
            email,
            lastNamePrefix,
        });
    }

    async deleteUser(id: string) {
        return this.delete(`users/${id}`);
    }

    async AssignRole(user_id: string, role_id: string, business_unit_id: string) {
        return this.post('user-role-assignments', { user_id, role_id, business_unit_id });
    }

    async updateRole(user_id: string, role_id: string, business_unit_id: string) {
        return this.patch(`user-role-assignments/${user_id}`, { role_id, business_unit_id });
    }

    async getRole(id: string) {
        return this.get(`user-role-assignments/${id}`);
    }

    async getUsers() {
        return this.get('users');
    }

    async getUser(id: string) {
        return this.post(`users/${id}`);
    }

    async getRoles() {
        return this.get('user-roles');
    }

    async getFlows() {
        return this.get('flow/list');
    }

    async getContents() {
        return this.get('content/list');
    }

    async authRecover(email: string, recaptcha: string) {
        return this.post('auth/recover', { email, recaptcha });
    }

    async authRecoverInEmail(email: string) {
        return this.post('auth/recover-from-mail', { email });
    }

    async authReset(token: string, password: string) {
        return this.post('auth/reset', { token, password });
    }

    async executeSprinklrApi(data: { app: number; endpoint: string; method: string; body: string }) {
        return this.post('sprinklr-api/debug/' + data.app, data);
    }

    async postMessage(
        appId: number,
        clientId: string,
        data: {
            posttime: string;
            imageurl: string;
            name: string;
            direct: string;
            campaign: string;
            message: string;
            account: string;
        },
    ) {
        return this.post('sprinklr-api/post-message/' + appId + '/' + clientId, data);
    }

    async getWebhookSubscriptions(appId: number | string) {
        return this.get('sprinklr-api/webhook-subscriptions/' + appId);
    }

    async getWebhookTypes(appId: number | string) {
        return this.get('sprinklr-api/webhook-types/' + appId);
    }

    async postWebhookSubscription(appId: number | string, payload: { [p: string]: any }) {
        return this.post('sprinklr-api/webhook-subscriptions/' + appId, payload);
    }

    async putWebhookSubscription(appId: number | string, id: string, payload: { [p: string]: any }) {
        return this.post('sprinklr-api/webhook-subscriptions/' + appId + '/' + id, payload);
    }

    async removeWebhookSubscription(appId: number | string, id: string) {
        return this.delete('sprinklr-api/webhook-subscriptions/' + appId + '/' + id);
    }

    async activateWebhookSubscription(appId: number | string, webhookId: string) {
        return this.post('sprinklr-api/webhook-subscriptions-activate/' + appId + '/' + webhookId, {});
    }

    async deactivateWebhookSubscription(appId: number | string, webhookId: string) {
        return this.post('sprinklr-api/webhook-subscriptions-deactivate/' + appId + '/' + webhookId, {});
    }

    async verifyWebhookSubscription(appId: number | string, webhookId: string) {
        return this.post('sprinklr-api/webhook-subscriptions-verify/' + appId + '/' + webhookId, {});
    }

    async getExternalSystems() {
        return this.get('external-systems');
    }

    async getExternalSystemSprinklrEnvironments() {
        return this.get('external-systems/sprinklr-environments');
    }

    async deleteExternalSystem(id: string) {
        return this.delete('external-systems/' + id);
    }

    async addExternalSystem(type: string, name: string, params: any) {
        return this.post('external-systems', { type, name, params });
    }

    async updateExternalSystem(extSystemId: string, type: string, name: string, params: any) {
        return this.patch('external-systems/' + extSystemId, { type, name, params });
    }

    async getExternalSystem(extSystemId: string) {
        return this.get('external-systems/' + extSystemId);
    }

    async postAssetUnzipTest(testData: {[key: string]: any}, columns: {[key: string]: string}, customProps: {[key: string]: { [key: string]: string }}) {
        return this.post('asset-unzip/test', {
            testData,
            columns,
            customProps,
        });
    }
}

const api = new Api('');

export default api;

import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 0,
  for: "fieldX"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_variable_selector = _resolveComponent("variable-selector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.inRow)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.field.label), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_variable_selector, {
      object: _ctx.object,
      modelValue: _ctx.modelValue.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.value) = $event)),
      mode: _ctx.field.params.mode,
      typesallowed: _ctx.field.params.typesAllowed
    }, null, 8, ["object", "modelValue", "mode", "typesallowed"])
  ]))
}
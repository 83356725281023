import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-info"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.field.params.message)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.field.params.message), 1),
        _createElementVNode("button", {
          class: "btn btn-lightdark",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
        }, _toDisplayString(_ctx.field.params.label), 1)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "btn btn-lightdark",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
        }, _toDisplayString(_ctx.field.params.label), 1)
      ]))
}
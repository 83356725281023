
import { Inject, Prop, Provide, VModel, Watch } from 'vue-property-decorator';
import Spinner from '@/components/common/Spinner.vue';
import { Options, Vue } from 'vue-class-component';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import { Field, FieldValue } from '@/components/automation/flows/designer/ConfigComponents/ObjectConfig.vue';
import { ConfigComponent } from '@/components/automation/flows/designer/ConfigComponents/config-components';
import { BaseObject } from '@/components/automation/flows/definitions/FlowDefinitions';
import ValueSelector from '@/components/automation/flows/designer/ValueSelector.vue';

@ConfigComponent('value-selector')
@Options({
    components: {
        ValueSelector,
        Spinner,
    },
})
export default class ConfigValueSelector extends Vue {
    @Inject() vm!: DesignerViewModel;

    @Prop()
    protected inRow: boolean = false;

    @Prop()
    public object!: BaseObject;

    @Prop()
    protected field!: Field;

    @Prop()
    protected modelValue!: FieldValue;

    @Watch('modelValue', { deep: true })
    onModelValueChanged() {
        this.$emit('input', this.modelValue);
    }
}


import { Component, Prop, Watch } from 'vue-property-decorator';
import { Created, Mounted } from '@/decorators/LifeCycle';
import { BaseObject } from '@/components/automation/flows/definitions/FlowDefinitions';
import { Options, Vue } from 'vue-class-component';

type AnyObject = { [key: string]: any };

@Options({})
export default class VariableSelector extends Vue {
    @Prop() public modelValue!: { name: string; scope: string };
    @Prop() public typesallowed!: string[];
    @Prop() public object!: BaseObject;
    @Prop() public type!: string;
    @Prop() public mode!: string;

    writemode: string = 'input';
    advmode: boolean = false;
    editval: { name: string; scope: string; type: string; meta: AnyObject } | false = false;
    currentval: { type: string; name: string; scope: string; meta: AnyObject } = {
        type: '',
        name: '',
        scope: '',
        meta: {},
    };
    ready: boolean = false;
    xsds: string[] = [];
    _typesallowed!: string[];

    vars: { [key: string]: { name: string; type: string; scope: string; meta: AnyObject } } = {};

    @Created
    initCurrentVal() {
        this.currentval = Object.assign(this.currentval, { type: this.type }, this.modelValue);
        if (!this.typesallowed) {
            this._typesallowed = [this.type];
        } else {
            this._typesallowed = this.typesallowed;
        }
    }

    startEdit() {
        this.editval = {
            name: this.currentval.name,
            scope: this.currentval.scope || 'stack',
            type: this.currentval.type ?? this.typesallowed[0],
            meta: this.currentval.meta,
        };

        if (this.editval.meta.schema) {
            this.editval.meta.schema = JSON.stringify(this.editval.meta.schema);
        }

        this.advmode = false
    }
    confirm() {
        if (this.editval) {
            const newval = Object.assign({}, this.editval);
            if (newval.meta.schema) {
                newval.meta.schema = JSON.parse(newval.meta.schema);
            }

            this.currentval = newval;
            this.$emit('update:modelValue', this.currentval);
            this.editval = false;
        }
    }

    @Watch('editval.name')
    selectedVariable() {
        if (this.mode === 'read' || this.writemode === 'select') {
            for (let varname in this.vars) {
                if (this.editval && varname === this.editval.name) {
                    this.editval.type = this.vars[varname].type;
                    this.editval.scope = this.vars[varname].scope;
                    this.editval.meta = this.vars[varname].meta;
                }
            }
        }
    }

    @Watch('currentval')
    validate() {
        this.$emit('validitychange', !!this.vars[this.currentval.name]);
    }

    @Mounted
    fetchVars() {
        this.object
            .remote()
            .getExistingVariables()
            .then((vars) => {
                console.log('vars', vars);
                for (let i in vars) {
                    const variable = vars[i];
                    if (this._typesallowed.indexOf(variable.type) !== -1) {
                        this.vars[variable.name] = variable;
                    }
                }

                this.validate();
            });
    }

    @Mounted
    fetchXsds() {
        // TODO
        // HTTP.get(['flow', 'get-xsds']).then((response: any) => {
        //     this.xsds = response.data;
        // });
    }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ffde8cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "designer" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "save-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toolbar = _resolveComponent("toolbar")!
  const _component_designer_canvas = _resolveComponent("designer-canvas")!
  const _component_object_library = _resolveComponent("object-library")!
  const _component_flow_status = _resolveComponent("flow-status")!
  const _component_add_object_window = _resolveComponent("add-object-window")!
  const _component_ObjectConfigWindow = _resolveComponent("ObjectConfigWindow")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_toolbar, { onRemoveSelected: _ctx.removeSelectedObject }, null, 8, ["onRemoveSelected"]),
    (_ctx.vm.ready)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "designer-wrapper",
          ref: "wrapper",
          onMousemove: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onLibraryDragOver($event))),
          onMouseup: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onLibraryDragStop($event)))
        }, [
          _createVNode(_component_designer_canvas, {
            ref: "canvas",
            onActivateObject: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onActivateObject($event))),
            onViewObjectLogs: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onViewObjectLogs($event))),
            onAddObject: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showStartDialog('add', $event)))
          }, null, 512),
          (!_ctx.vm.readonly && _ctx.vm.advanced)
            ? (_openBlock(), _createBlock(_component_object_library, {
                key: 0,
                onObjectMouseDown: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onLibraryObjectMouseDown($event.event, $event.objectType)))
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_flow_status),
          (_ctx.libraryDrag.confirmed)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(['library-drag-object', _ctx.libraryDrag.objectType.category]),
                style: _normalizeStyle({ left: _ctx.libraryDrag.x + 'px', top: _ctx.libraryDrag.y + 'px' })
              }, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.libraryDrag.objectType.title), 1),
                _createElementVNode("span", {
                  class: _normalizeClass(['category-circle', _ctx.libraryDrag.objectType.category])
                }, null, 2),
                _createElementVNode("img", {
                  src: _ctx.vm.getIconUrl(_ctx.libraryDrag.objectType.icon),
                  class: "darkmode-invert"
                }, null, 8, _hoisted_3)
              ], 6))
            : _createCommentVNode("", true)
        ], 544))
      : _createCommentVNode("", true),
    (_ctx.vm.saving)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.addObjectWindow)
      ? (_openBlock(), _createBlock(_component_add_object_window, _mergeProps({ key: 2 }, _ctx.addObjectWindow, {
          onObjectSelected: _cache[6] || (_cache[6] = ($event: any) => (_ctx.addObject($event))),
          onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.addObjectWindow = false))
        }), null, 16))
      : _createCommentVNode("", true),
    (_ctx.editconfig)
      ? (_openBlock(), _createBlock(_component_ObjectConfigWindow, {
          key: 3,
          object: _ctx.editconfig,
          onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onCloseObjectWindow())),
          onSave: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onSaveObject($event))),
          onViewObjectLogs: _ctx.onViewObjectLogsFromConfig,
          readonly: _ctx.vm.readonly
        }, null, 8, ["object", "onViewObjectLogs", "readonly"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ]))
}
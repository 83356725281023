
import { Options, Vue } from 'vue-class-component';
import api from '@/shared/api';
import session from '@/shared/session';

@Options({
    components: {},
})
export default class RecoverPassword extends Vue {
    protected error = '';
    protected requested = false;
    protected email = '';

    async recoverPassword() {
        this.error = '';
        try {
            const data = await api.authRecover(
                this.email,
                (new FormData(this.$refs.form as HTMLFormElement).get('g-recaptcha-response') as string) ?? '',
            );
            console.log('authRecover result', data);
            this.requested = true;
        } catch (error) {
            console.error(error);
            this.error = String(error).toString();
        }
    }

    mounted() {
        const w = window as unknown as any;
        const render = () => {
            if (w.recaptchaReady) {
                (w.grecaptcha as any).render(this.$refs.recaptcha, {
                    theme: 'dark',
                    sitekey: '6LctU0UkAAAAAO3Q_G5TYc0QIdCrYBRnia5HCHYj',
                });
            } else {
                setTimeout(render, 100);
            }
        };

        render();
    }
}

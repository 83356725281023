
import { Options, Vue } from 'vue-class-component';
import api from '@/shared/api';
import session from '@/shared/session';

interface BusinessUnit {
    id: string;
    name: string;
    parent: BusinessUnit | null;
}

@Options({
    components: {},
    props: {},
})
export default class ManageBusinessUnits extends Vue {
    private businessUnits: BusinessUnit[] = [];
    private CurrentBusinessUnit: string | null = '';
    private showCreateForm = true;
    private newBusinessUnitName = '';
    private businessUnitsLoaded = false;
    private updateBuId: string | null = null;
    private updatedBusinessUnitName = '';
    private deleteBuId: string | null = null;
    private updatedBusinessUnitParent: BusinessUnit | null = null;
    private updatedBusinessUnitParent_ID: string | null = null;

    // Delete
    private async deleteBusinessUnit(id: string) {
        try {
            await api.deleteBusinessUnit(id);
            this.businessUnits = this.businessUnits.filter((bu) => bu.id !== id);
        } catch (error) {
            console.error(`Error deleting business unit ${id}:`, error);
        }
    }

    private async confirmDeleteBusinessUnit() {
        if (this.deleteBuId) {
            await this.deleteBusinessUnit(this.deleteBuId);
            this.deleteBuId = null;
            this.loadBusinessUnits();
        }
    }

    private cancelDeleteBusinessUnit() {
        this.deleteBuId = null;
    }

    private async startDeleteBusinessUnit(id: string) {
        this.deleteBuId = id;
    }

    // Update
    private async updateBusinessUnit(id: string, newName: string, parent_id: string | null) {
        // Update the business unit in the database
        if (typeof parent_id === 'string') {
            await api.updateBusinessUnit(id, newName, parent_id);
        }
    }

    private async confirmUpdateBusinessUnit(id: string) {
        if (this.updateBuId && this.updatedBusinessUnitName) {
            await this.updateBusinessUnit(id, this.updatedBusinessUnitName, this.updatedBusinessUnitParent_ID);
            this.updateBuId = null;
            this.updatedBusinessUnitName = '';
            this.updatedBusinessUnitParent_ID = null;
            this.loadBusinessUnits();
        }
    }

    private cancelUpdateBusinessUnit() {
        this.updateBuId = null;
        this.updatedBusinessUnitName = '';
        this.updatedBusinessUnitParent_ID = null;
    }

    private async startUpdateBusinessUnit(id: string, name: string, parent_id: string | null) {
        this.updateBuId = id;
        this.updatedBusinessUnitName = name;
        this.updatedBusinessUnitParent_ID = parent_id;
    }

    // Get
    private getLastBusinessUnitId(): string {
        const sortedBusinessUnits = this.businessUnits.slice().sort((a, b) => Number(b.id) - Number(a.id));
        return sortedBusinessUnits.length > 0 ? sortedBusinessUnits[0].id : '0';
    }

    private async loadBusinessUnits() {
        try {
            const businessUnits = await api.getBusinessUnits();
            this.businessUnits = businessUnits;
            this.businessUnitsLoaded = true;
        } catch (error) {
            console.error('Error loading business units:', error);
        }
    }

    private async getCorrectBusinessUnits() {
        try {
            // Get all Business Units
            const businessUnits = await api.getBusinessUnits();
            this.businessUnits = businessUnits;
            this.businessUnitsLoaded = true;

            // Get Current Business Unit
            const currentBu = this.businessUnits.find((bu) => bu.id === session.getCurrentBusinessUnit());
            console.log('current name :', currentBu);

            // Get all children Units
            const childBU = this.businessUnits.filter((bu) => bu.parent?.id === currentBu?.id);
            console.log('child BU :', childBU);

            // Get All
            this.businessUnits = currentBu ? [currentBu, ...childBU] : [...childBU];
        } catch (error) {
            console.error('Error loading business units:', error);
        }
    }

    private getParentName(parent: BusinessUnit | null): string {
        if (!parent) {
            return '-';
        }
        return parent.name;
    }

    mounted() {
        this.getCorrectBusinessUnits();
    }
}

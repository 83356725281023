
import { Options, Vue } from 'vue-class-component';
import AppTile from './AppTile.vue';
import { Prop } from 'vue-property-decorator';
import api from '@/shared/api';
import { mergeDeep } from '@/shared/mergedeep';
import readXlsxFile from 'read-excel-file';
import Modal from '@/components/common/Modal.vue';
import moment from 'moment';
import asyncTimeout from '@/shared/async-timer';

type Column = {
    name: string;
    value?: string;
    createCustomFieldValues?: boolean;
};

type RuleSet = {
    name: string;
    clientId: string;
    columns: { [columnName: string]: Column };
    filetypes: { [key: string]: boolean };
};

@Options({
    components: {
        AppTile,
        Modal,
    },
})
export default class BulkMessageSettings extends Vue {
    @Prop()
    protected app: undefined | any = undefined;

    protected ready = false;

    protected status: 'editing' | 'importing' | 'posting' = 'editing';

    protected currentClient: null | string = null;

    protected messageTemplate = {
        message: { value: 'message' },
        posttime: { date: '2023-03-28', time: '18:44' },
        campaign: { value: '', error: false },
        name: { value: '' },
        account: { value: '', error: false },
        direct: { value: '' },
        imageurl: { value: '', assetId: false },
    };

    protected messages = [{ fields: mergeDeep({}, this.messageTemplate as any), status: 'editing', selected: false }];

    protected importPopUp = false;
    protected postPopUp: false | { error: false | string; messageCount: number; progress: number; done: boolean } =
        false;

    addMessage() {
        this.messages.push(mergeDeep({}, { fields: this.messageTemplate, status: 'editing', selected: false } as any));
    }

    removeMessages() {
        for (let i = this.messages.length - 1; i >= 0; i--) {
            const message = this.messages[i];
            if (message.status === 'editing' && message.selected) {
                this.messages.splice(i, 1);
            }
        }
    }

    removePostedMessages() {
        for (let i = this.messages.length - 1; i >= 0; i--) {
            const message = this.messages[i];
            if (message.status === 'posted') {
                this.messages.splice(i, 1);
            }
        }
    }

    selectAll() {
        for (let i in this.messages) {
            const message = this.messages[i];
            if (message.status === 'editing') {
                message.selected = true;
            }
        }
    }

    get sortedAccounts() {
        const result = [];
        if (
            this.clients &&
            this.currentClient &&
            this.clients[this.currentClient] &&
            this.clients[this.currentClient].accounts
        ) {
            for (let accountId in this.clients[this.currentClient].accounts) {
                result.push({
                    accountId,
                    data: this.clients[this.currentClient].accounts[accountId],
                });
            }

            result.sort((a, b) => {
                return a.data.name.localeCompare(b.data.name);
            });
        }
        return result;
    }

    get sortedCampaigns() {
        const result = [];
        if (
            this.clients &&
            this.currentClient &&
            this.clients[this.currentClient] &&
            this.clients[this.currentClient].campaigns
        ) {
            for (let campaignId in this.clients[this.currentClient].campaigns) {
                result.push({
                    campaignId,
                    data: this.clients[this.currentClient].campaigns[campaignId],
                });
            }

            result.sort((a, b) => {
                return a.data.name.localeCompare(b.data.name);
            });
        }
        return result;
    }

    async postMessages() {
        if (this.currentClient) {
            this.status = 'posting';
            this.postPopUp = { error: false, messageCount: 0, progress: 0, done: false };
            this.$forceUpdate();

            const messageIdx: number[] = [];
            for (let i in this.messages) {
                const message = this.messages[i];
                if (message.status === 'editing' && message.selected) {
                    this.messages[i].status = 'posting';
                    messageIdx.push(parseInt(i));
                }
            }

            if (messageIdx.length) {
                this.postPopUp.messageCount = messageIdx.length;
            } else {
                this.postPopUp.error = 'No messages selected to post';
                return;
            }

            for (let i in messageIdx) {
                const index = messageIdx[i];
                const message = this.messages[index];

                const result = await api.postMessage(this.app.id, this.currentClient, {
                    message: message.fields.message.value,
                    posttime: message.fields.posttime.date + ' ' + message.fields.posttime.time,
                    campaign: message.fields.campaign.value,
                    name: message.fields.name.value,
                    account: message.fields.account.value,
                    direct: message.fields.direct.value,
                    imageurl: message.fields.imageurl.value,
                });

                if (result && result.success) {
                    message.status = 'posted';
                } else {
                    message.status = 'error';
                }
                console.log(result);

                this.postPopUp.progress++;
                this.$forceUpdate();
            }

            this.postPopUp.done = true;
        }
    }

    async importFile() {
        const importFiles = (this.$refs.importFile as HTMLInputElement).files;
        if (importFiles && importFiles.length > 0) {
            const importFile = importFiles[0];
            readXlsxFile(importFile).then(async (rows) => {
                const assetIdRequests: [string, number][] = [];
                if (rows && rows.length > 0) {
                    const columns = rows[0];

                    for (let rowIdx = 1; rowIdx < rows.length; rowIdx++) {
                        const rowObject: { [key: string]: any } = {};
                        for (let colIdx = 0; colIdx < columns.length; colIdx++) {
                            if (rows[rowIdx][colIdx]) {
                                rowObject[columns[colIdx].toString()] = rows[rowIdx][colIdx].toString();
                            }
                        }
                        //     POST-TIME
                        // :
                        //     "02/22/2023 12:30:00 PM GMT+01:00"

                        let campaignId = '';
                        let campaignIdFound = true;
                        if (rowObject['CAMPAIGN-NAME']) {
                            if (
                                this.currentClient &&
                                this.clients[this.currentClient] &&
                                this.clients[this.currentClient].campaignsByName &&
                                this.clients[this.currentClient].campaignsByName[rowObject['CAMPAIGN-NAME']]
                            ) {
                                campaignId =
                                    this.clients[this.currentClient].campaignsByName[rowObject['CAMPAIGN-NAME']];
                            } else {
                                campaignIdFound = false;
                            }
                        }

                        let accountId = '';
                        let accountIdFound = true;
                        if (rowObject['CAMPAIGN-NAME']) {
                            if (
                                this.currentClient &&
                                this.clients[this.currentClient] &&
                                this.clients[this.currentClient].accountsByTypeAndName &&
                                this.clients[this.currentClient].accountsByTypeAndName[
                                    rowObject['ACCOUNT-TYPE'] + '/' + rowObject['ACCOUNT-NAME']
                                ]
                            ) {
                                accountId =
                                    this.clients[this.currentClient].accountsByTypeAndName[
                                        rowObject['ACCOUNT-TYPE'] + '/' + rowObject['ACCOUNT-NAME']
                                    ];
                            } else {
                                accountIdFound = false;
                            }
                        }

                        let match,
                            assetId: false | string = false;
                        if (
                            rowObject['IMAGE-URL'] &&
                            (match = rowObject['IMAGE-URL'].match(
                                /^https:\/\/[-a-z0-9]+\.sprinklr\.com\/[^/]+\/asset-manager\?v=([^&]+)/,
                            ))
                        ) {
                            let path = decodeURIComponent(match[1]);
                            match = path.match(/^\/MEDIA_ASSET\/([^/]+)/);
                            if (match) {
                                assetId = match[1];
                            }
                        }

                        let postTime = moment(rowObject['POST-TIME'], 'MM/DD/YYYY hh:mm:ss A [GMT]ZZ');

                        this.messages.push({
                            fields: mergeDeep(
                                {},
                                this.messageTemplate as any,
                                {
                                    message: { value: rowObject.MESSAGE },
                                    posttime: { date: postTime.format('YYYY-MM-DD'), time: postTime.format('HH:mm') },
                                    campaign: { value: campaignId, error: !campaignIdFound },
                                    name: { value: rowObject['MESSAGE-NAME'] },
                                    account: { value: accountId, error: !accountIdFound },
                                    direct: { value: rowObject['DIRECT'] === 'true' ? 'publish' : 'draft' },
                                    imageurl: { value: rowObject['IMAGE-URL'], assetId: assetId },
                                } as any,
                            ),
                            status: 'editing',
                            selected: false,
                        });

                        if (assetId) {
                            assetIdRequests.push([assetId, this.messages.length - 1]);
                        }
                        console.log('rowObject', rowObject);
                    }

                    this.importPopUp = false;
                    this.$forceUpdate();

                    if (this.currentClient) {
                        console.log('assetIdRequests', assetIdRequests);
                        for (let assetIdRequest of assetIdRequests) {
                            const [assetId, messageIdx] = assetIdRequest;
                            const assetData = await api.getSprinklrAsset(this.app.id, this.currentClient, assetId);
                            console.log('assetData', assetData.digitalAsset.mediaUrl);
                            this.messages[messageIdx].fields.imageurl.assetId = false;
                            this.messages[messageIdx].fields.imageurl.value = assetData.digitalAsset.mediaUrl;
                        }
                    }
                }
            });
        }
    }

    protected clients: { [clientId: string]: { [key: string]: any } } = {};
    protected error = '';

    async created() {
        try {
            let clients = await api.getSprinklrClients(this.app.id);
            for (let client of clients) {
                this.clients[client.clientId] = client;
            }
        } catch (e) {
            this.error = 'Error while retrieving Sprinklr Clients';
            return;
        }

        for (const clientId in this.clients) {
            const client = this.clients[clientId];

            try {
                client.campaigns = {};
                client.campaignsByName = {};

                const campaigns = await api.getSprinklrCampaigns(this.app.id, client.clientId);
                const subCampaigns = [];

                for (let i = 0; i < campaigns.length; i++) {
                    if (campaigns[i].archived || campaigns[i].deleted) continue;

                    if (campaigns[i].parentCampaignId) {
                        subCampaigns.push(campaigns[i]);
                    } else {
                        client.campaigns[campaigns[i].id] = { name: campaigns[i].name, subCampaigns: [] };
                    }
                }

                for (let i = 0; i < subCampaigns.length; i++) {
                    let subCampaign = subCampaigns[i];
                    if (subCampaign) {
                        let campaign = client.campaigns[subCampaign.parentCampaignId];
                        if (campaign) {
                            campaign.subCampaigns.push({ name: subCampaign.name });
                        }
                    }
                }

                for (let i in client.campaigns) {
                    let campaign = client.campaigns[i];
                    if (campaign) {
                        client.campaignsByName[campaign.name] = i;
                    }
                }
            } catch (e) {
                console.error(e);
                client.campaigns = {};
                client.campaignsByName = {};
            }

            try {
                client.accounts = {};
                client.accountsByTypeAndName = {};

                let accounts = await api.getSprinklrPartnerAccounts(this.app.id, client.clientId);

                accounts = accounts.sort((a: { displayName: string }, b: { displayName: string }) => {
                    return a.displayName.localeCompare(b.displayName);
                });

                for (let i = 0; i < accounts.length; i++) {
                    client.accounts[accounts[i].accountId] = {
                        name: accounts[i].displayName,
                        type: accounts[i].accountType,
                    };
                    client.accountsByTypeAndName[accounts[i].accountType + '/' + accounts[i].displayName] =
                        accounts[i].accountId;
                }
            } catch (e) {
                console.error(e);
                client.accounts = {};
                client.accountsByTypeAndName = {};
            }
        }

        this.ready = true;
    }

    public getSettings() {
        return mergeDeep(this.app.settings);
    }
}

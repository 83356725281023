
import { Provide, Prop, Inject, InjectReactive, Watch } from 'vue-property-decorator';
import { BaseObject, Connection, ObjectDefinition, Waypoint } from '@/components/automation/flows/definitions/FlowDefinitions';
import { BeforeCreate, Created, Mounted } from '@/decorators/LifeCycle';
import { Options, Vue } from 'vue-class-component';
import ObjectConfig from '@/components/automation/flows/designer/ConfigComponents/ObjectConfig.vue';
import moment from 'moment';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import api from '@/shared/api';

@Options({
    components: { ObjectConfig },
})
export default class ContextLogsWindow extends Vue {
    @Inject() vm!: DesignerViewModel;
    @InjectReactive()
    protected now!: number;

    protected context: any = null;

    get nowMoment() {
        return moment(this.now);
    }

    protected logs = [];

    formatDate(date: string): string {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }

    formatDateDiff(date1: string, date2: string): string {
        // if the difference in seconds is lower than 120, show the seconds
        if (moment(date2).diff(moment(date1), 'seconds') < 120) {
            return moment(date2).diff(moment(date1), 'seconds') + ' seconds';
        }
        return moment(date1).from(moment(date2), true);
    }

    formatDateAgo(date: string): string {
        if (this.nowMoment) {
            return moment(date).fromNow();
        }
        return '';
    }

    @Created
    @Watch('vm', { deep: true })
    async getLogs(): Promise<void> {
        this.context = await api.get('flow/get-context/' + this.$route.params.contextId);

        this.logs = [];
    }
}


import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    props: {},
})
export default class Modal extends Vue {
    @Prop() title!: string;
    @Prop() minWidth!: string;
    @Prop() cancelButton!: boolean;

    @Prop() showFooter = true;
}


import { Options, Vue } from 'vue-class-component';
import { Inject, Provide } from 'vue-property-decorator';
import { Flow } from '@/components/automation/flows/designer/Flow';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import { FlowObjectData, ObjectDefinition } from '@/components/automation/flows/definitions/FlowDefinitions';
import { Created } from '@/decorators/LifeCycle';

@Options({
    props: {},
})
export default class ObjectLibrary extends Vue {
    @Inject() vm!: DesignerViewModel;
    ready = false;

    search = '';

    @Created
    init() {
        this.ready = true;
    }

    get filteredObjects() {
        if (this.search) {
            const categories: any = {};

            for (const category in this.vm.groupedObjectTypes) {
                const objects = this.vm.groupedObjectTypes[category].filter((objectType: ObjectDefinition) => {
                    return objectType.title.toLowerCase().includes(this.search.toLowerCase());
                });
                if (objects.length > 0) {
                    categories[category] = objects;
                }
            }

            return categories;
        }
        return this.vm.groupedObjectTypes;
    }
}


import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ContextMenuItem from '@/components/common/ContextMenuItem';

@Options({})
export default class ContextMenu extends Vue {
    @Prop() position!: { top: number; left: number };
    @Prop() context!: any[];
    @Prop() menus!: ContextMenuItem[];
    @Prop() element!: HTMLElement | null;

    show: boolean = false;
    calculatedHeight = 0;

    @Watch('context')
    async contextChanged(): Promise<void> {
        setTimeout(() => {
            this.calculatedHeight = this.$el.clientHeight;
        }, 1);

        await this.retrievePermitted();

        this.show = true;
    }

    get calculatedPosition(): { top: number; left: number } {
        const extraSpacePixels = 16;
        const pixelsOutsideWindow = this.position.top + this.calculatedHeight - window.innerHeight + extraSpacePixels;
        return {
            top: this.position.top - (pixelsOutsideWindow > 0 ? pixelsOutsideWindow : 0),
            left: this.position.left,
        };
    }

    click(item: any, event: MouseEvent, newWindow: boolean = false): void {
        this.show = false;
        this.$emit('contextMenuItemClick', item, this.element, event, newWindow);
    }

    isDisabled(contextMenu: ContextMenuItem): boolean {
        if (this.isHeader(contextMenu)) {
            return true;
        }

        if (contextMenu.selectionMode === 'single' && this.context.length > 1) {
            return true;
        }

        if (typeof contextMenu.disabled === 'boolean') {
            return contextMenu.disabled;
        }

        return this.context.some((row) =>
            typeof contextMenu.disabled === 'boolean' ? contextMenu.disabled : contextMenu.disabled(row),
        );
    }

    isHeader(contextMenu: ContextMenuItem): boolean {
        if (typeof contextMenu.header === 'boolean') {
            return contextMenu.header;
        }

        return this.context.some((row) =>
            typeof contextMenu.header === 'boolean' ? contextMenu.header : contextMenu.header(row),
        );
    }

    isHidden(contextMenu: ContextMenuItem): boolean {
        if (contextMenu.selectionMode === 'single' && this.context.length > 1) {
            return true;
        }

        if (typeof contextMenu.hidden === 'boolean') {
            return contextMenu.hidden;
        }

        return this.context.some((row) =>
            typeof contextMenu.hidden === 'boolean' ? contextMenu.hidden : contextMenu.hidden(row),
        );
    }

    async isPermitted(contextMenu: ContextMenuItem): Promise<boolean> {
        if (contextMenu.selectionMode === 'single' && this.context.length > 1) {
            return true;
        }
        console.log('contextMenu', contextMenu);
        if (typeof contextMenu.permission === 'boolean') {
            return contextMenu.permission;
        }

        const asyncSome = async (arr: any, predicate: any) => {
            for (const e of arr) {
                if (await predicate(e)) return true;
            }
            return false;
        };

        return await asyncSome(this.context, async (row: any) =>
            typeof contextMenu.permission === 'boolean' ? contextMenu.permission : await contextMenu.permission(row),
        );
    }

    protected permitted: boolean[] = [];

    async retrievePermitted() {
        for (let index = 0; index < this.menus.length; index++) {
            const item = this.menus[index];
            this.permitted[index] = await this.isPermitted(item);
        }
    }

    getTitle(contextMenu: ContextMenuItem) {
        if (typeof contextMenu.title === 'function' && this.context.length > 0) {
            return contextMenu.title(this.context[0]);
        }

        return contextMenu.title as string;
    }

    getIcon(contextMenu: ContextMenuItem) {
        if (typeof contextMenu.icon === 'function' && this.context.length > 0) {
            return contextMenu.icon(this.context[0]);
        }

        return contextMenu.icon as string;
    }
}

<template>
    <!--    <ul class="folders">-->
    <table :style="tableStyle" :class="tableClass">
        <thead>
            <tr v-if="additionalHeaders && additionalHeaders.length">
                <th
                    v-for="hdr in additionalHeaders"
                    :key="hdr.header"
                    :style="{ width: hdr.width }"
                    :colspan="hdr.colspan ? hdr.colspan : 1"
                    :class="hdr.headerClass"
                >
                    {{ hdr.header }}
                </th>
            </tr>
            <tr>
                <th
                    v-for="column in columns"
                    :key="column.header"
                    :style="{ width: column.width }"
                    :class="column.cellClass"
                >
                    {{ column.header }}
                </th>
            </tr>
        </thead>
        <tbody>
            <folder
                :show="true"
                :folder="folder"
                :columns="columns"
                :expandedIconClass="expandedIconClass"
                :collapsedIconClass="collapsedIconClass"
                :depth="0"
                :hideRoot="hideRoot"
                @select="onSelect($event)"
                @deselect="onDeSelect($event)"
            ></folder>
        </tbody>
    </table>
    <!--    </ul>-->
</template>

<script>
import Folder from './Folder.vue';

export default {
    name: 'tree-table',
    props: {
        folder: Object,
        collapsedIconClass: String,
        expandedIconClass: String,
        additionalHeaders: Array,
        columns: Array,
        tableStyle: Object,
        tableClass: [Array, String],
        multiSelect: Boolean,
        hideRoot: Boolean,
    },
    data: () => {
        return {
            selection: [],
        };
    },
    provide: function () {
        return {
            selection: this.selection,
        };
    },
    components: {
        Folder,
    },
    methods: {
        onSelect(folder) {
            if (!this.multiSelect) {
                this.selection.splice(0, this.selection.length);
            }

            this.selection.push(folder);

            this.$emit('select', this.selection);
        },
        onDeSelect(folder) {
            if (this.selection.indexOf(folder) !== -1) {
                this.selection.splice(this.selection.indexOf(folder), 1);
                this.$emit('select', this.selection);
            }
        },
    },
};
</script>

<style scoped>
th {
    padding: 1rem 0rem;
}
/*ul.folders {*/
/*    padding: 1rem;*/
/*    margin: 0;*/
/*    box-sizing: border-box;*/
/*    width: 100%;*/
/*    list-style: none*/
/*}*/
/*ul.folders > li:first-child {*/
/*    padding: 1rem 1rem 1rem 0*/
/*}*/
</style>


import { Options, Vue } from 'vue-class-component';
// import {Validate, ValidationRuleSet} from "@/decorators/Validation";
// import {required} from '../../validation/rules';
// import Validation from "@/components/Shared/Validation/Validation";
import { Inject, InjectReactive } from 'vue-property-decorator';
import { ListViewModel } from '@/components/automation/flows/designer/ListViewModel';
import { Flow } from '@/components/automation/flows/designer/Flow';
import Modal from '@/components/common/Modal.vue';

@Options({
    components: {
        Modal,
    },
})
export default class Add extends Vue /*TODO: VueExtend(Validation)*/ {
    @Inject() listVM!: ListViewModel;

    private name: string = '';

    // @ValidationRuleSet()
    // validation = {
    //     'name': [required]
    // }

    onClose() {
        this.$router.push({ name: 'automation-flows' });
    }

    // @Validate('validation')
    async onConfirm() {
        let id = await this.listVM.add(new Flow({ name: this.name }));
        await this.$router.push({ name: 'automation-flows-edit', params: { flowId: id } });
    }
}

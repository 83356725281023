
import { Options, Vue } from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import Modal from '@/components/common/Modal.vue';

@Options({
    props: {},
    components: {
        Modal,
    },
})
export default class Toolbar extends Vue {
    @Inject() vm!: DesignerViewModel;

    save() {
        this.vm.flow.save();
    }
}

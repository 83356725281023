import { Flow } from '@/components/automation/flows/designer/Flow';
import asyncTimeout from '@/shared/async-timer';
import api from '@/shared/api';
import {Content} from "@/components/content/Content";

export default class ContentStore {
    async fetch() {
        // TODO:
        // console.log('waiting...');
        // await asyncTimeout(1000);
        // console.log('fetched');
        // return [];
        // return HTTP.get(['rest', 'flow', 'get'])
        //     .then(response => response.data.data.map(flow => new Flow(flow)));
        return await api.getContents();
    }

    async add(content: Content) {
        const response = await api.addContent(content.type, content.name);
        // TODO
        // return HTTP.post(['rest', 'flow', 'post'], {name: flow.name})
        //     .then(response => response.data.data.id);
        return response.id;
    }
}

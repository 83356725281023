import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 0,
  for: "fieldX"
}
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.inRow)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.field.label), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("select", {
      class: "form-control form-select",
      id: "fieldX",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.value) = $event)),
      "aria-describedby": "helpX",
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.field.hasDependencies && _ctx.$emit('dependency-change')))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.field.params.options, (option, optionIdx) => {
        return (_openBlock(), _createElementBlock("option", {
          key: optionIdx,
          value: option.value ?? option
        }, _toDisplayString(option.label ?? option), 9, _hoisted_3))
      }), 128))
    ], 544), [
      [_vModelSelect, _ctx.modelValue.value]
    ])
  ]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_object_config_component = _resolveComponent("object-config-component")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.field.fieldOrder, (fieldName, fieldIdx) => {
    return (_openBlock(), _createElementBlock("td", { key: fieldIdx }, [
      (_ctx.ready)
        ? (_openBlock(), _createBlock(_component_object_config_component, {
            key: 0,
            field: _ctx.field.children[fieldName],
            value: _ctx.modelValue.childValues[fieldName],
            object: _ctx.object,
            "in-row": true,
            onInput: ($event: any) => (_ctx.fieldChanged(_ctx.field.children[fieldName], fieldName))
          }, null, 8, ["field", "value", "object", "onInput"]))
        : _createCommentVNode("", true)
    ]))
  }), 128))
}

import { Options, Vue } from 'vue-class-component';
import AppTile from './AppTile.vue';
import api from '@/shared/api';
import { LocationQueryValue } from 'vue-router';
import getAppDefinitions, { AppDefinitions } from '@/shared/apps';

@Options({
    components: {
        AppTile,
    },
})
export default class AppSprinklrAuthorize extends Vue {
    protected app: null | any = null;
    protected apps: AppDefinitions = {};

    protected environments = ['prod', 'prod2', 'prod3', 'prod4', 'prod8', 'prod12', 'prod-zero', 'qa4'];
    protected selectedEnvironment = 'prod3';
    protected authorizeReturn = false;

    openSprinklr() {
        const env = window.location.port !== '443' && window.location.port !== '' ? 'development' : 'prod';
        const state = env + '-' + this.app.app_key;
        const appData = this.apps[this.app.app_name];
        window.location.href =
            'https://api2.sprinklr.com/' +
            this.selectedEnvironment +
            '/oauth/authorize?client_id=' +
            appData.api_keys[this.selectedEnvironment].key +
            '&response_type=code&redirect_uri=' +
            appData.redirect_uri +
            '&state=' +
            state;
    }

    async created() {
        this.apps = await getAppDefinitions();

        if (this.$route.query && this.$route.query.code) {
            this.authorizeReturn = true;
            const code: string = this.$route.query.code as string;
            const env: string = this.$route.query.env as string;
            const appKey = (this.$route.query.state as string).split('-').splice(1).join('-');
            console.log('auth code', code);
            console.log('appKey', appKey);

            const result = await api.authorizeApp(appKey, code, env);
            await this.$router.push({ name: 'app-settings', params: { appId: result.app_id } });
        } else {
            const appId = parseInt(this.$route.params.appId as string);

            this.app = await api.getAppSettings(appId);
        }
    }

    protected removeModal = false;
    async removeApp() {
        this.removeModal = true;
    }
    async removeAppConfirm() {
        const appId = parseInt(this.$route.params.appId as string);
        await api.removeApp(appId);

        this.$router.push({ name: 'app-store' });
    }
}

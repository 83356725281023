
import { Inject, Prop, Provide, VModel } from 'vue-property-decorator';
import Spinner from '@/components/common/Spinner.vue';
import { Options, Vue } from 'vue-class-component';
import { ListViewModel } from '@/components/automation/flows/designer/ListViewModel';
import { reactive } from 'vue';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import { Field, FieldValue } from '@/components/automation/flows/designer/ConfigComponents/ObjectConfig.vue';
import { ConfigComponent } from '@/components/automation/flows/designer/ConfigComponents/config-components';

@ConfigComponent('checkbox')
@Options({
    components: {
        Spinner,
    },
})
export default class ConfigCheckbox extends Vue {
    @Inject() vm!: DesignerViewModel;

    @Prop()
    protected inRow: boolean = false;

    @Prop()
    protected field!: Field;

    @Prop()
    protected modelValue!: FieldValue;
}


import { Options, Vue } from 'vue-class-component';
import api from '@/shared/api';
import getAppDefinitions, { AppDefinitions } from '@/shared/apps';
import { Watch } from 'vue-property-decorator';
import moment from 'moment';
import Modal from '@/components/common/Modal.vue';

type WebHookType = {
    id?: string;
    name?: string;
    description?: string;
    subscriptions?: string[];
    filteredSubscriptions?: { webhookType: string }[];
    url?: string;
    preSharedKey?: string;
    viaProxy?: boolean;
    verified?: boolean;
    active?: boolean;
    lastSuccessTimestamp?: number;
    lastFailedTimestamp?: number;
    subscriptionsObject?: { [key: string]: boolean };
};
type EditWebhookNewType = {
    new: true;
    data: WebHookType;
};
type EditWebhookEditType = {
    new: false;
    idx: number;
    data: WebHookType;
};
type EditWebHookType = false | EditWebhookNewType | EditWebhookEditType;

type ExternalSystem = {
    id: string;
    type: string;
    name: string;
    created_at: string;
};

@Options({
    components: {
        Modal,
    },
    props: {},
})
export default class SprinklrApiWebhooks extends Vue {
    protected installedApps = [];
    protected availableApps: AppDefinitions = {};
    protected externalSystems: ExternalSystem[] = [];
    protected webhooks: WebHookType[] = [];
    protected webhookTypes = [];

    protected ready = false;
    protected appId: number | string | null = null;

    protected editWebhook: EditWebHookType = false;
    protected removeWebhook: WebHookType | false = false;

    get sprinklrExternalSystems() {
        return this.externalSystems.filter((system) => system.type === 'sprinklr');
    }

    async created() {
        this.externalSystems = await api.getExternalSystems();
        this.availableApps = await getAppDefinitions();
        this.installedApps = (await api.getInstalledApps()).filter(
            (app: { authenticated: boolean }) => app.authenticated,
        );

        this.ready = true;
    }

    @Watch('appId')
    async retrieveWebhooks() {
        if (this.appId) {
            this.webhooks = await api.getWebhookSubscriptions(this.appId);
            this.webhookTypes = await api.getWebhookTypes(this.appId);
        } else {
            this.webhooks = [];
            this.webhookTypes = [];
        }
    }

    formatDate(date: string) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }

    startRemove(idx: number) {
        if (this.webhooks[idx]) {
            this.removeWebhook = this.webhooks[idx];
        }
    }

    async remove() {
        if (this.removeWebhook && this.removeWebhook.id && this.appId) {
            await api.removeWebhookSubscription(this.appId, this.removeWebhook.id);
            this.removeWebhook = false;
            await this.retrieveWebhooks();
        }
    }

    async deactivate(webhookId: string) {
        if (this.appId) {
            await api.deactivateWebhookSubscription(this.appId, webhookId);
            await this.retrieveWebhooks();
        }
    }

    async activate(webhookId: string) {
        if (this.appId) {
            await api.activateWebhookSubscription(this.appId, webhookId);
            await this.retrieveWebhooks();
        }
    }

    async verify(webhookId: string) {
        if (this.appId) {
            await api.verifyWebhookSubscription(this.appId, webhookId);
            await this.retrieveWebhooks();
        }
    }

    startNew() {
        this.editWebhook = {
            new: true,
            data: { subscriptionsObject: {} },
        };
    }

    startEdit(idx: number) {
        if (this.webhooks[idx]) {
            const subscriptionsObject: { [key: string]: boolean } = {};
            const subscriptions = this.webhooks[idx].subscriptions;
            if (subscriptions) {
                for (let subscriptionIdx in subscriptions) {
                    const subscription = subscriptions[subscriptionIdx];
                    subscriptionsObject[subscription] = true;
                }
            }
            this.editWebhook = {
                new: false,
                idx,
                data: Object.assign({ subscriptionsObject }, this.webhooks[idx]),
            };
        }
    }

    async save() {
        if (this.editWebhook && this.appId) {
            const payload: { [key: string]: any } = {
                name: this.editWebhook.data.name,
                description: this.editWebhook.data.description,
                url: this.editWebhook.data.url,
                preSharedKey: this.editWebhook.data.preSharedKey,
            };

            if (this.editWebhook.new) {
                payload.subscriptions = [];
                for (let subscription in this.editWebhook.data.subscriptionsObject) {
                    if (
                        this.editWebhook.data.subscriptionsObject &&
                        this.editWebhook.data.subscriptionsObject[subscription]
                    ) {
                        payload.subscriptions.push(subscription);
                    }
                }

                await api.postWebhookSubscription(this.appId, payload);
            } else if (this.editWebhook.data.id) {
                const currentSubscriptions = this.webhooks[this.editWebhook.idx].subscriptions;
                if (currentSubscriptions) {
                    const newSubscriptions: string[] = [];
                    for (let subscription in this.editWebhook.data.subscriptionsObject) {
                        if (
                            this.editWebhook.data.subscriptionsObject &&
                            this.editWebhook.data.subscriptionsObject[subscription]
                        ) {
                            newSubscriptions.push(subscription);
                        }
                    }

                    payload.addSubscriptions = newSubscriptions.filter((item) => !currentSubscriptions.includes(item));
                    payload.removeSubscriptions = currentSubscriptions.filter(
                        (item) => !newSubscriptions.includes(item),
                    );
                }

                await api.putWebhookSubscription(this.appId, this.editWebhook.data.id, payload);
            }

            this.editWebhook = false;
            await this.retrieveWebhooks();

            console.log('payload', payload);
        }
    }
}

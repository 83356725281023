
import { Options, Vue } from 'vue-class-component';
import AppTile from './components/apps/AppTile.vue';
import TopBar from './components/common/TopBar.vue';
import { ProvideReactive } from 'vue-property-decorator';
import HelpWindow from '@/components/common/HelpWindow.vue';

@Options({
    components: {
        HelpWindow,
        TopBar,
        AppTile,
    },
})
export default class App extends Vue {
    protected apps = [
        {
            title: 'Expired Assets',
            description: 'Automatically update assets when they are about to expire.',
            installed: false,
        },
        {
            title: 'Some other app',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse leo sapien',
            installed: true,
        },
    ];

    get availableApps(): { [key: string]: any }[] {
        return this.apps.filter((app) => !app.installed);
    }

    get installedApps(): { [key: string]: any }[] {
        return this.apps.filter((app) => app.installed);
    }

    @ProvideReactive()
    public now = Date.now();

    created() {
        window.setInterval(() => {
            this.now = Date.now();
        }, 1000);
    }

    count = 0;

    onClick() {
        fetch(process.env.VUE_APP_API_URL as string, {
            method: 'POST',
        })
            .then((response) => response.text())
            .then((data) => {
                this.count = parseInt(data);
            });
    }

    protected help = {
        show: false,
        data: null,
    };

    showHelp(data: any = {}): void {
        this.help.show = true;
        this.help.data = data;
    }
}

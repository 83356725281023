
import {Inject, Prop, Provide, VModel, Watch} from 'vue-property-decorator';
import Spinner from '@/components/common/Spinner.vue';
import { Options, Vue } from 'vue-class-component';
import { ListViewModel } from '@/components/automation/flows/designer/ListViewModel';
import { reactive } from 'vue';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import { Field, FieldValue } from '@/components/automation/flows/designer/ConfigComponents/ObjectConfig.vue';
import ObjectConfigComponent from '@/components/automation/flows/designer/ConfigComponents/ObjectConfigComponent.vue';
import { ConfigComponent } from '@/components/automation/flows/designer/ConfigComponents/config-components';
import { BaseObject } from '@/components/automation/flows/definitions/FlowDefinitions';

@ConfigComponent('table')
@Options({
    components: {
        Spinner,
        'object-config-component': ObjectConfigComponent,
    },
    emits: ['dependency-change', 'custom-action'],
})
export default class ConfigTable extends Vue {
    @Inject() vm!: DesignerViewModel;

    @Prop()
    public object!: BaseObject;

    @Prop()
    protected field!: Field;

    @Prop()
    protected modelValue!: FieldValue;

    protected ready = false;

    @Watch('field.children', { deep: true })
    childrenChanged() {
        console.log('field.children Changed', this.field.children);

        if (!this.modelValue.childValues) {
            this.modelValue.childValues = {};
        }
        for (let i in this.field.children) {
            if (!this.modelValue.childValues[i]) {
                this.modelValue.childValues[i] = {
                    type: 'value',
                    value: '',
                };
            }
        }
    }

    created() {
        if (!this.modelValue.childValues) {
            this.modelValue.childValues = {};
        }

        for (let i in this.field.children) {
            if (!this.modelValue.childValues[i]) {
                this.modelValue.childValues[i] = {
                    type: 'value',
                    value: '',
                };
            }
        }

        console.log('field', this.field);

        this.ready = true;
    }

    addRow() {
        if (
            this.field.children &&
            this.field.fieldOrder &&
            this.field.params?.template &&
            this.modelValue.childValues
        ) {
            let counter = 0;
            while (this.field.children['child' + counter]) {
                counter++;
            }
            const fieldName = 'child' + counter;
            this.modelValue.childValues[fieldName] = {
                type: 'value',
                value: '',
            };
            this.field.children[fieldName] = this.field.params.template;
            this.field.fieldOrder.push(fieldName);
        }
    }

    removeRow(rowIndex: number) {
        if (this.field.children && this.field.fieldOrder && this.field.params?.template) {
            const fieldName = this.field.fieldOrder[rowIndex];
            delete this.field.children[fieldName];
            if (this.modelValue.childValues) {
                delete this.modelValue.childValues[fieldName];
            }
            this.field.fieldOrder.splice(rowIndex, 1);
        }
    }
}

/* eslint-disable @typescript-eslint/ban-types */

export const symbols: { [data: string]: symbol } = {};

const createLifeCylceDecorator = (
    lifeCycleType: string,
): ((target: any, propertyKey: string | symbol | Function, descriptor: PropertyDescriptor) => PropertyDescriptor) => {
    const lcSym = (symbols[lifeCycleType] = Symbol(lifeCycleType));

    type propKeyTypes = string | symbol | Function;

    return function (target: any, propertyKey: propKeyTypes, descriptor: PropertyDescriptor) {
        // If target is an object
        if (target && typeof target === 'object') {
            // If it hasn't our symbol yet, add it
            if (!Object.prototype.hasOwnProperty.call(target, lcSym)) {
                target[lcSym] = {
                    cycleable: [],
                    original: Object.prototype.hasOwnProperty.call(target, lifeCycleType) && target.lifeCycleType,
                };

                // (override/declare) the original function
                target[lifeCycleType] = function (...args: any[]) {
                    const { cycleable, original } = target[lcSym];
                    // console.log('Calling '+name+' functions');

                    // Call decorator declared functions
                    cycleable.forEach((el: propKeyTypes) => {
                        let func: Function;
                        switch (typeof el) {
                            case 'number':
                            case 'symbol':
                            case 'string':
                                func = target[el];
                                break;
                            case 'function':
                                func = el;
                                break;
                            default:
                                throw new TypeError();
                        }
                        func.apply(this, args);
                    });

                    // If we registered an original mounted function, call it
                    if (original) {
                        original.apply(this, args);
                    }
                };
            }
            target[lcSym].cycleable.push(propertyKey);
        }
        return descriptor;
    };
};

export const BeforeCreate = createLifeCylceDecorator('beforeCreate');
export const Created = createLifeCylceDecorator('created');
export const BeforeMount = createLifeCylceDecorator('beforeMount');
export const Mounted = createLifeCylceDecorator('mounted');
export const BeforeUpdate = createLifeCylceDecorator('beforeUpdate');
export const Updated = createLifeCylceDecorator('updated');
export const Activated = createLifeCylceDecorator('activated');
export const Deactivated = createLifeCylceDecorator('deactivated');
export const BeforeDestroy = createLifeCylceDecorator('beforeDestroy');
export const Destroyed = createLifeCylceDecorator('destroyed');
export const ErrorCaptured = createLifeCylceDecorator('errorCaptured');

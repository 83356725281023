
import { Options, Vue } from 'vue-class-component';
import TopBar from '../components/common/TopBar.vue';

@Options({
    components: {
        TopBar,
    },
})
export default class Center extends Vue {}

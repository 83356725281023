
import { Options, Vue } from 'vue-class-component';
import AppTile from './AppTile.vue';
import { Prop } from 'vue-property-decorator';
import api from '@/shared/api';

@Options({
    components: {
        AppTile,
    },
})
export default class UserGroupShareSettings extends Vue {
    @Prop()
    protected app: undefined | any = undefined;

    protected ready = false;
    protected error = '';

    protected dynamicUserGroupCustomProp = {};

    protected clients: { [key: string]: any }[] = [];
    protected customProps = [];

    async created() {
        try {
            this.clients = await api.getSprinklrClients(this.app.id);
        } catch (e) {
            this.error = 'Error while retrieving Sprinklr Clients';
            return;
        }

        for (const client of this.clients) {
            try {
                client.auth = true;
                client.customProps = await api.getSprinklrCustomProperties(this.app.id, client.clientId);
            } catch (e) {
                client.auth = false;
                client.customProps = [];
            }
        }

        this.dynamicUserGroupCustomProp =
            this.app.settings && this.app.settings.dynamicUserGroupCustomProp
                ? this.app.settings.dynamicUserGroupCustomProp
                : {};

        this.ready = true;
    }

    public getSettings() {
        return Object.assign(this.app.settings ?? {}, { dynamicUserGroupCustomProp: this.dynamicUserGroupCustomProp });
    }
}


import { Options, Vue } from 'vue-class-component';

@Options({
    props: {},
})
export default class TopBar extends Vue {

    currentMode = this.getCurrentMode();

    getCurrentMode() {
        return document.querySelector("html")?.dataset?.bsTheme;
    }

    toggleMode(mode: string) {
        const html = document.querySelector("html");
        if (html) {
            html.dataset.bsTheme = mode;
            this.currentMode = mode;
        }
    }

}

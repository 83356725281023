
import { Options, Vue } from 'vue-class-component';
import TopBar from '../components/common/TopBar.vue';
import SideBar from '../components/common/SideBar.vue';

@Options({
    components: {
        TopBar,
        SideBar,
    },
})
export default class Main extends Vue {}


import { Inject, Prop, Provide } from 'vue-property-decorator';
import Spinner from '@/components/common/Spinner.vue';
import { Options, Vue } from 'vue-class-component';
import { ListViewModel } from '@/components/automation/flows/designer/ListViewModel';
import { reactive } from 'vue';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import { Field } from '@/components/automation/flows/designer/ConfigComponents/ObjectConfig.vue';
import { ConfigComponent } from '@/components/automation/flows/designer/ConfigComponents/config-components';
import {BaseObject} from "@/components/automation/flows/definitions/FlowDefinitions";

@ConfigComponent('button')
@Options({
    components: {
        Spinner,
    },
    emits: ['custom-action'],
})
export default class ConfigButton extends Vue {
    @Inject() vm!: DesignerViewModel;

    @Prop()
    protected field!: Field;

    @Prop()
    public object!: BaseObject;

    async onClick() {
        this.$emit('custom-action', this.field.params?.action);
    }
}


import { Options, Vue } from 'vue-class-component';
import AppTile from './AppTile.vue';
import getAppDefinitions, { AppDefinitions } from '@/shared/apps';
import api from '@/shared/api';

@Options({
    components: {
        AppTile,
    },
})
export default class AppOverview extends Vue {
    protected apps: AppDefinitions = {};

    get appName() {
        let appname = this.$route.params.appname;
        if (appname instanceof Array) {
            appname = appname[0];
        }
        return appname;
    }
    get app() {
        return this.apps[this.appName];
    }

    async installApp() {
        const appId = await api.installApp(this.appName);

        await this.$router.push({ name: this.app.install.route, params: { appId: appId } });
    }

    async created() {
        this.apps = await getAppDefinitions();
    }
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2da4beda"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "caret" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "fad fa-buildings fa-fw"
}
const _hoisted_5 = {
  key: 1,
  class: "fad fa-building fa-fw"
}
const _hoisted_6 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tree_item = _resolveComponent("tree-item", true)!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.businessUnits, (bu, idx) => {
      return (_openBlock(), _createElementBlock("li", { key: idx }, [
        _createElementVNode("div", {
          class: "bu-item",
          onClick: ($event: any) => (_ctx.$emit('select', bu))
        }, [
          _createElementVNode("div", _hoisted_2, [
            (bu.children && bu.children.length)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(["fa fa-fw", {
                            'fa-caret-right': _ctx._expandedItems.indexOf(bu.id) === -1,
                            'fa-caret-down': _ctx._expandedItems.indexOf(bu.id) !== -1,
                        }]),
                  onClick: _withModifiers(($event: any) => (_ctx.toggleExpand(bu.id)), ["stop","prevent"])
                }, null, 10, _hoisted_3))
              : _createCommentVNode("", true)
          ]),
          (bu.children && bu.children.length)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4))
            : (_openBlock(), _createElementBlock("span", _hoisted_5)),
          _createElementVNode("span", _hoisted_6, _toDisplayString(bu.name), 1)
        ], 8, _hoisted_1),
        (bu.children && bu.children.length && _ctx._expandedItems.indexOf(bu.id) !== -1)
          ? (_openBlock(), _createBlock(_component_tree_item, {
              key: 0,
              "business-units": bu.children,
              onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', $event))),
              "expanded-items": _ctx.expandedItems
            }, null, 8, ["business-units", "expanded-items"]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}
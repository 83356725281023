<template>
    <tr
        v-if="!hideRoot"
        class="folder"
        :class="[folder.leaf ? 'is-leaf' : 'is-folder', selection.indexOf(folder) !== -1 ? 'selected' : '']"
        :style="{ display: show ? 'table-row' : 'none' }"
        @click.stop="select()"
    >
        <template v-for="(column, idx) in columns">
            <td v-if="column.type === 'tree'" :key="idx" :class="column.cellClass">
                <div class="item" :style="{ paddingLeft: depth * 20 + 'px' }">
                    <span class="collapse-icon" @click="expand()">
                        <i
                            v-if="!folder.leaf"
                            :class="['fa fa-fw', folder.expanded ? 'fa-angle-down' : 'fa-angle-right']"
                        ></i>
                    </span>

                    <span class="icon">
                        <i v-if="!folder.leaf" :class="folder.expanded ? expandedIconClass : collapsedIconClass"></i>
                        <i v-if="folder.leaf" :class="folder.iconClass"></i>
                    </span>

                    <span class="text">{{ folder.text }}</span>
                </div>
            </td>

            <td
                v-if="column.type === 'text'"
                :key="idx"
                v-html="renderField(folder, column)"
                :class="column.cellClass"
            ></td>
        </template>
    </tr>

    <folder
        v-for="(child, childIdx) in folder.children"
        :folder="child"
        :key="childIdx + ':' + child.path"
        :expandedIconClass="expandedIconClass"
        :collapsedIconClass="collapsedIconClass"
        :depth="depth + 1"
        :columns="columns"
        :show="show && folder.children && folder.children.length > 0 && folder.expanded"
        @select="$emit('select', $event)"
        @deselect="$emit('deselect', $event)"
    ></folder>

    <tr
        class="folder-empty"
        :style="{
            paddingLeft: depth * 20 + 'px',
            display: show && !folder.leaf && folder.expanded && folder.children.length === 0 ? 'table-row' : 'none',
        }"
    >
        <td :colspan="columns.length">No Data</td>
    </tr>
</template>

<script>
export default {
    components: {},
    name: 'folder',
    props: {
        folder: Object,
        collapsedIconClass: String,
        expandedIconClass: String,
        depth: Number,
        columns: Array,
        show: Boolean,
        hideRoot: Boolean,
    },
    inject: ['selection'],
    methods: {
        select() {
            if (this.selection.indexOf(this.folder) !== -1) {
                this.$emit('deselect', this.folder);
            } else {
                this.$emit('select', this.folder);
            }
        },
        expand() {
            if (this.folder.leaf) {
                return;
            }

            // eslint-disable-next-line vue/no-mutating-props
            this.folder.expanded = !this.folder.expanded;
        },
        renderField(data, colDef) {
            let value;

            if (colDef.field) {
                const field = colDef.field.split('.');
                value = data;
                for (let i = 0; i < field.length; i++) {
                    let f = field[i];
                    if (value && (i == field.length - 1 || value[f])) {
                        value = value[f];
                    } else {
                        value = '';
                        break;
                    }
                }
            }

            if (colDef.render) {
                return colDef.render(value);
            } else {
                return value;
            }
        },
    },
};
</script>

<style scoped lang="scss">
td .item {
    padding: 1rem;
}
tr:hover td {
    background-color: #eeeeee;
    cursor: pointer;
}

tr.selected td {
    background-color: #0072b8;
    color: #ffffff;
}

tr.is-folder {
    /*margin-bottom: 0.5rem*/
}
tr.is-folder > span.text {
    padding: 0.5rem;
    display: inline-block;
}
.collapse-icon {
    display: inline-block;
    margin: 0px 0px 0px 5px;
    width: 1rem;
    width: 1rem;
}
.icon {
    display: inline-block;
    margin: 0px 0px 0px 10px;
    width: 1.5rem;
}
tr.is-leaf {
    /*padding: 0 0 0 1rem;*/
    /*color: #000;*/
}
div.sub-folders {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    list-style: none;
}
div.folder-empty {
    padding: 1rem 1rem 1rem 3rem;
    color: #000;
    opacity: 0.5;
}
</style>


import { Inject, Prop, Provide, VModel, Watch } from 'vue-property-decorator';
import Spinner from '@/components/common/Spinner.vue';
import { Options, Vue } from 'vue-class-component';
import { ListViewModel } from '@/components/automation/flows/designer/ListViewModel';
import { reactive } from 'vue';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import { Field, FieldValue } from '@/components/automation/flows/designer/ConfigComponents/ObjectConfig.vue';
import { ConfigComponent } from '@/components/automation/flows/designer/ConfigComponents/config-components';

let uid = 0;

@ConfigComponent('radio')
@Options({
    components: {
        Spinner,
    },
})
export default class ConfigRadio extends Vue {
    @Inject() vm!: DesignerViewModel;

    @Prop()
    protected inRow: boolean = false;

    @Prop()
    protected field!: Field;

    @Prop()
    protected modelValue!: FieldValue;

    protected uid!: number;

    beforeCreate() {
        this.uid = uid++;
    }

    radioName() {
        return 'radio' + this.uid;
    }

    @Watch('modelValue', { deep: true })
    onModelValueChanged() {
        this.$emit('input', this.modelValue);
    }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a9ad6380"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_bar = _resolveComponent("top-bar")!
  const _component_side_bar = _resolveComponent("side-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_top_bar, {
      onHelp: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('help', $event)))
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_side_bar),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ], 64))
}
export default function Debounce(delay: number) {
    return function (target: any, key: string, descriptor: PropertyDescriptor) {
        const method = descriptor.value;
        let timeout: any;

        descriptor.value = function (...args: any[]) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                return method.apply(this, args);
            }, delay);
        };
    };
}

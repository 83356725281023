import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_help_window = _resolveComponent("help-window")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$route.meta.layout), { onHelp: _ctx.showHelp }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }, 40, ["onHelp"])),
    (_ctx.help.show)
      ? (_openBlock(), _createBlock(_component_help_window, _mergeProps({
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.help.show = false))
        }, _ctx.help.data), null, 16))
      : _createCommentVNode("", true)
  ]))
}
import ViewModel from '@/components/content/ViewModel';
import {Content} from "@/components/content/Content";
import ContentStore from "@/components/content/ContentStore";

class ListViewModel implements ViewModel {
    public loading = true;

    private contentStore = new ContentStore();

    public contents: Content[] = [];

    init() {
        this.fetchAll();
    }

    fetchAll() {
        Promise.all([this.fetchContents()]).then(() => {
            this.loading = false;
            console.log('done loading');
        });
    }

    async fetchContents() {
        this.contents = await this.contentStore.fetch();
    }

    get byGroup() {
        return this.contents.reduce((byGroup: { [key: string]: Content[] }, content) => {
            if (!byGroup[content.group]) {
                byGroup[content.group] = [];
            }
            byGroup[content.group].push(content);
            return byGroup;
        }, {});
    }

    get groups() {
        return this.contents.map((content) => content.group).filter((value, index, self) => self.indexOf(value) === index);
    }

    async add(content: Content) {
        const id = await this.contentStore.add(content);
        await this.fetchContents();
        return id;
    }
}

export { ListViewModel };

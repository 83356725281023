
import { Options, Vue } from 'vue-class-component';
import session from '@/shared/session';
import { Prop, Watch } from 'vue-property-decorator';
import Modal from '@/components/common/Modal.vue';

type BusinessUnitData = {
    id: string;
    name: string;
    parent?: string;
    children: BusinessUnitData[];
};

@Options({
    components: {
        Modal,
    },
    props: {},
})
export default class BusinessUnitSwitcher extends Vue {
    @Prop()
    businessUnits: BusinessUnitData[] = [];

    @Prop()
    expandedItems: string[] = [];

    _expandedItems: string[] = [];

    created() {
        if (this.expandedItems) {
            this._expandedItems = this.expandedItems.slice(0);
        }
    }

    toggleExpand(id: string) {
        if (this._expandedItems.indexOf(id) === -1) {
            this._expandedItems.push(id);
        } else {
            this._expandedItems.splice(this._expandedItems.indexOf(id), 1);
        }
    }
}

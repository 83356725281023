import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tree_item = _resolveComponent("tree-item")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, {
    title: "Select Business Unit",
    onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel'))),
    "show-footer": false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_tree_item, {
        "business-units": _ctx.businessUnits,
        onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', $event.id))),
        "expanded-items": _ctx.expandedItems
      }, null, 8, ["business-units", "expanded-items"])
    ]),
    _: 1
  }))
}
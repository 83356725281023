
import { Options, Vue } from 'vue-class-component';
import api from '@/shared/api';
import { Watch } from 'vue-property-decorator';

export type ExternalSystem = {
    id: string;
    type: string;
    name: string;
    created_at: string;
};

type ExternalSystemTypes = {
    name: string;
    label: string;
    addRoute: any;
    icon: string;
};

@Options({
    components: {},
    props: {},
})
export default class ExternalSystems extends Vue {
    protected ready = false;
    protected externalSystems: ExternalSystem[] = [];

    protected externalSystemTypes: { [key: string]: ExternalSystemTypes } = {
        sprinklr: {
            name: 'sprinklr',
            label: 'Sprinklr Account',
            icon: require('../../../assets/external-systems/sprinklr.png'),
            addRoute: { name: 'external-systems-authorize-sprinklr', params: { newOrId: 'new' } },
        },
        microsoftazure: {
            name: 'microsoftazure',
            label: 'Microsoft Account',
            icon: require('../../../assets/external-systems/microsoft.svg'),
            addRoute: { name: 'external-systems-authorize-microsoftazure', params: { newOrId: 'new' } },
        },
    };

    async created() {
        await this.refresh();
        // this.externalSystems.push({
        //     id: 'id1',
        //     type: 'sprinklr',
        //     name: 'some name',
        //     created_at: ''
        // });
        // this.externalSystems.push({
        //     id: 'id2',
        //     type: 'sprinklr',
        //     name: 'some oter name',
        //     created_at: ''
        // });

        this.ready = true;
    }

    @Watch('$route', { deep: true })
    async refresh() {
        this.externalSystems = await api.getExternalSystems();
    }

    async deleteSystem(id: string) {
        await api.deleteExternalSystem(id);
        await this.refresh();
    }

    async reauthSystem(id: string) {
        const type = this.externalSystems.find((system) => system.id === id)?.type;
        if (type) {
            this.$router.push(Object.assign({}, this.externalSystemTypes[type].addRoute, { params: { newOrId: id } }));
        }
    }
}


import { Inject, Prop, Provide, Watch } from 'vue-property-decorator';
import Spinner from '@/components/common/Spinner.vue';
import { Options, Vue } from 'vue-class-component';
import { ListViewModel } from '@/components/automation/flows/designer/ListViewModel';
import { reactive } from 'vue';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import ObjectConfigComponent from '@/components/automation/flows/designer/ConfigComponents/ObjectConfigComponent.vue';
import { BaseObject } from '@/components/automation/flows/definitions/FlowDefinitions';
import { cloneDeep } from '@/shared/clonedeep';
import { AnyObject } from '@/shared/any-object';

require('@/components/automation/flows/designer/ConfigComponents/ConfigMessage.vue');
require('@/components/automation/flows/designer/ConfigComponents/ConfigButton.vue');
require('@/components/automation/flows/designer/ConfigComponents/ConfigTextInput.vue');
require('@/components/automation/flows/designer/ConfigComponents/ConfigTextAreaInput.vue');
require('@/components/automation/flows/designer/ConfigComponents/ConfigTable.vue');
require('@/components/automation/flows/designer/ConfigComponents/ConfigTableRow.vue');
require('@/components/automation/flows/designer/ConfigComponents/ConfigVariableSelector.vue');
require('@/components/automation/flows/designer/ConfigComponents/ConfigValueSelector.vue');
require('@/components/automation/flows/designer/ConfigComponents/ConfigSelect.vue');
require('@/components/automation/flows/designer/ConfigComponents/ConfigCheckbox.vue');
require('@/components/automation/flows/designer/ConfigComponents/ConfigPlaceholder.vue');
require('@/components/automation/flows/designer/ConfigComponents/ConfigGroup.vue');
require('@/components/automation/flows/designer/ConfigComponents/ConfigRadio.vue');
require('@/components/automation/flows/designer/ConfigComponents/ConfigLabel.vue');
require('@/components/automation/flows/designer/ConfigComponents/ConfigDayInterval.vue');

export type Field = {
    type: string;
    label?: string;
    params?: { [key: string]: any };
    children?: { [key: string]: Field };
    fieldOrder?: string[];
    hasDependencies?: boolean;
    helpText?: any;
};
export type FieldValue = {
    type: 'value' | 'delegated';
    value?: string;
    childValues?: { [key: string]: FieldValue };
    flowParam?: string;
};

@Options({
    components: {
        ObjectConfigComponent,
        Spinner,
    },
    emits: ['dependency-change', 'helptext', 'custom-action'],
})
export default class ObjectConfig extends Vue {
    @Inject() vm!: DesignerViewModel;

    protected ready = false;

    @Prop()
    public object!: BaseObject;

    @Prop()
    protected children: { [key: string]: Field } = {};

    @Prop()
    protected fieldOrder: string[] = [];
    //
    // protected children: { [key: string]: Field } = {
    //     noconfig: {
    //         type: 'message',
    //         params: {
    //             message: 'No configuration needed',
    //         },
    //     },
    //     test1: {
    //         type: 'text-input',
    //         label: 'test',
    //         params: {
    //             placeholder: 'Fill something in',
    //         },
    //     },
    //     table1: {
    //         type: 'table',
    //         params: {
    //             header: ['Column #1', 'Column #2'],
    //             template: {
    //                 type: 'table-row',
    //                 children: {
    //                     cell1: {
    //                         type: 'text-input',
    //                         params: {
    //                             placeholder: 'Fill something in',
    //                         },
    //                     },
    //                     cell2: {
    //                         type: 'text-input',
    //                         params: {
    //                             placeholder: 'Fill something else in',
    //                         },
    //                     },
    //                 },
    //                 fieldOrder: ['cell1', 'cell2'],
    //             },
    //         },
    //         children: {
    //             row1: {
    //                 type: 'table-row',
    //                 children: {
    //                     cell1: {
    //                         type: 'text-input',
    //                         params: {
    //                             placeholder: 'Fill something in',
    //                         },
    //                     },
    //                     cell2: {
    //                         type: 'text-input',
    //                         params: {
    //                             placeholder: 'Fill something else in',
    //                         },
    //                     },
    //                 },
    //                 fieldOrder: ['cell1', 'cell2'],
    //             },
    //             row2: {
    //                 type: 'table-row',
    //                 children: {
    //                     cell1: {
    //                         type: 'text-input',
    //                         params: {
    //                             placeholder: 'Fill something in',
    //                         },
    //                     },
    //                     cell2: {
    //                         type: 'text-input',
    //                         params: {
    //                             placeholder: 'Fill something else in',
    //                         },
    //                     },
    //                 },
    //                 fieldOrder: ['cell1', 'cell2'],
    //             },
    //         },
    //         fieldOrder: ['row1', 'row2'],
    //     },
    // };
    // protected fieldOrder: string[] = ['noconfig', 'test1', 'table1'];

    @Prop()
    protected modelValue = {};

    getConfig(): AnyObject {
        return this.fieldValues;
    }

    protected fieldValues: { [key: string]: FieldValue } = {};

    @Watch('children', { deep: true })
    childrenChanged() {
        console.log('childrenChanged', this.children);
        for (let i in this.children) {
            if (!this.fieldValues[i]) {
                this.fieldValues[i] = {
                    type: 'value',
                    value: '',
                };
            }
        }
        // TODO: this cleanup should also be done in the other components with children possibly?
        for (let i in this.fieldValues) {
            if (!this.children[i]) {
                delete this.fieldValues[i];
            }
        }
    }

    created() {
        console.log('modelValue', this.modelValue);
        this.fieldValues = cloneDeep(this.modelValue);
        console.log('field', this.children);
        console.log('fieldOrder', this.fieldOrder);
        console.log('fieldValues', this.fieldValues);
        for (let i in this.children) {
            if (!this.fieldValues[i]) {
                this.fieldValues[i] = {
                    type: 'value',
                    value: '',
                };
            }
        }

        this.ready = true;
    }

    async runCustomAction(action: any) {
        window.setTimeout(async () => {
            console.log('runCustomAction', action);
            //@ts-ignore
            const result = await this.object.remote()[action](this.getConfig());

            // Object.assign(this, result[1]);
            this.fieldValues = Object.assign(this.fieldValues, result);
            this.$emit('dependency-change', action);
            // console.log('objectConfig reloaded', values, this);
        }, 1);
    }
}


import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Created, Mounted } from '@/decorators/LifeCycle';

@Options({
    props: {},
})
export default class HelpWindow extends Vue {
    @Prop() title!: string;
}

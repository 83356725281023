
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Created, Mounted } from '@/decorators/LifeCycle';
import { BaseObject } from '@/components/automation/flows/definitions/FlowDefinitions';
import TreeTable from '@/components/common/treetable/Root.vue';
import { Options, Vue } from 'vue-class-component';
import ValueSelectorTree from '@/components/automation/flows/designer/ValueSelectorTree.vue';

type VariableValue = {
    type: 'variable';
    variable: string;
};

type XpathValue = {
    type: 'xpath';
    xpath: string;
    variable: string;
};

type ValueValue = {
    type: 'value';
    value: string;
};

type CodeValue = {
    type: 'code';
    code: string;
};

type Value = VariableValue | XpathValue | CodeValue | ValueValue;

@Options({
    components: { ValueSelectorTree, TreeTable },
})
export default class ValueSelector extends Vue {
    @Prop() private modelValue!: Value;
    @Prop() private object!: BaseObject;

    editval: Value | false = false;
    currentval: Value = { type: 'value', value: '' };
    ready: boolean = false;

    vars: { [key: string]: { name: string; type: string; scope: string; meta: any } } = {};

    xmlTree: false | { component: any; props: { [key: string]: any } } = false;

    getVarTreeOptions(schema: any = null, value: string | null = null): { [key: string]: any } {
        if (schema) {
            if (schema.type === 'object' && schema.properties) {
                return {
                    label: (value ?? '').split('.').reverse()[0],
                    description: schema.description ?? schema.title,
                    children: Object.keys(schema.properties).map((key) => {
                        return this.getVarTreeOptions(schema.properties[key], value + '.' + key);
                    }),
                };
            } else if (schema.type === 'array' && schema.items) {
                // return schema.items.map((child: any, idx: number) => {
                //     return Object.assign(child, {
                //         value: `[${idx}].${child.value}`,
                //         label: `[${idx}]`,
                //         children: this.getVarTreeOptions(child),
                //     });
                // });
                return {
                    value,
                    label: schema.title ?? (value ?? '').split('.').reverse()[0],
                    children: Array.isArray(schema.items) ? schema.items.map((child: any, idx: number) => {
                        if (child.type === 'object') {
                            return this.getVarTreeOptions(child, `${value}.[${idx}]`);
                            // return {
                            //     value: `[${idx}]`,
                            //     label: `[${idx}]`,
                            //     children: [this.getVarTreeOptions(child)],
                            // };
                        }
                        return {
                            value: `${value}.[${idx}]`,
                            label: `[${idx}]`,
                        };
                    }) : [],
                };
            } else {
                return {
                    value,
                    label: schema.title ?? (value ?? '').split('.').reverse()[0],
                };
            }
        } else {
            return Object.keys(this.vars).map((key) => {
                if (this.vars[key].meta && this.vars[key].meta.schema) {
                    return Object.assign(
                        {
                            value: this.vars[key].name,
                            label: this.vars[key].name,
                            collection: this.vars[key].meta && this.vars[key].meta.collection,
                        },
                        this.getVarTreeOptions(this.vars[key].meta && this.vars[key].meta.schema, this.vars[key].name),
                    );
                }
                return {
                    value: this.vars[key].name,
                    label: this.vars[key].name,
                    collection: this.vars[key].meta && this.vars[key].meta.collection,
                };
                //
                // return {
                //     value: this.vars[key].name,
                //     label: this.vars[key].name,
                //     children:
                //         this.vars[key].meta && this.vars[key].meta.schema
                //             ? this.getVarTreeOptions(this.vars[key].meta.schema).map((child) => {
                //                   return Object.assign(child, { value: `${this.vars[key].name}.${child.value}` });
                //               })
                //             : [],
                // };
            });
        }
    }

    selectTreeNode(selection: any[]) {
        console.log('selectTreeNode', selection);
        this.editval = Object.assign(this.editval, { xpath: selection[0] ? selection[0].path : null });
    }

    @Created
    initCurrentVal() {
        this.currentval = Object.assign(this.currentval, this.modelValue);
    }

    confirm() {
        if (this.editval) {
            this.currentval = this.editval;
            this.$emit('update:modelValue', this.currentval);
            this.editval = false;
        }
    }

    @Watch('currentval', { deep: true })
    currentValChanged() {
        this.$emit('update:modelValue', this.currentval);
    }

    @Watch('editval', { deep: true })
    async updateXmlTree() {
        if (this.editval && this.editval.type === 'xpath') {
            this.xmlTree = false;

            await this.$nextTick();

            const vars = await this.object.remote().getExistingVariables();
            for (let varname in vars) {
                if (varname === this.editval.variable && vars[varname].meta && vars[varname].meta.xsd) {
                    const tree = await this.getXsdTree(vars[varname].meta);

                    // this.xmlTree = {
                    //     component: TreeTable,
                    //     props: {
                    //         folder: tree[0],
                    //         columns: [{type: 'tree', header: 'Select node', width: 'auto', cellClass: 'tree'}],
                    //         'table-class': 'w-100'
                    //     }
                    // };

                    this.$forceUpdate();

                    return;
                }
            }
        }
    }

    @Mounted
    fetchVars() {
        this.object
            .remote()
            .getExistingVariables()
            .then(async (vars) => {
                const xmlVars: { text: string; expanded: boolean; children: any[] } = {
                    text: 'XML Variables',
                    expanded: true,
                    children: [],
                };
                console.log('vars', vars);
                for (let i in vars) {
                    const variable = vars[i];
                    this.vars[variable.name] = variable;

                    if (variable.meta && variable.meta.xsd) {
                        const tree = await this.getXsdTree(variable.meta);

                        xmlVars.children.push({
                            text: variable.name,
                            children: Array.isArray(tree) ? tree : [],
                        });
                    }
                }
                console.log('this.vars', this.vars);

                console.log(xmlVars);

                this.xmlTree = {
                    component: TreeTable,
                    props: {
                        'folder': xmlVars,
                        'columns': [{ type: 'tree', header: '', width: 'auto', cellClass: 'tree' }],
                        'table-class': 'w-100',
                        'hideRoot': true,
                    },
                };
            });
    }

    async getXsdTree(meta: { xsd: string; tree?: any }) {
        // TODO:
        // const response = await HTTP.post(['flow', 'get-xsd-tree'], {xsd: meta.xsd});
        //
        // const fixTree = (tree) => {
        //     let result = tree;
        //     for (const i in result) {
        //         if (result[i].items && result[i].items.length) {
        //             result[i].children = fixTree(result[i].items);
        //             delete result[i].items;
        //         } else {
        //             result[i].children = [];
        //             result[i].leaf = true;
        //             delete result[i].items;
        //         }
        //     }
        //     return result;
        // };
        // meta.tree = fixTree(response.data);
        // console.log(meta);
        //
        // return meta.tree;
    }
}


import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import TreeItem from '@/components/common/business-unit-switcher/TreeItem.vue';
import Modal from '@/components/common/Modal.vue';
import session from '@/shared/session';

type BusinessUnitData = {
    id: string;
    name: string;
    parent_id?: string;
    children: BusinessUnitData[];
};

@Options({
    components: {
        Modal,
        TreeItem,
    },
    props: {},
})
export default class BusinessUnitSwitcher extends Vue {
    @Prop()
    businessUnits: BusinessUnitData[] = [];

    @Prop()
    currentBusinessUnit: string = '';

    get expandedItems() {
        const expandedItems = [];
        const buById: { [key: string]: BusinessUnitData } = {};
        for (let bu of session.getBusinessUnits()) {
            buById[bu.id] = Object.assign({ children: [] }, bu);
        }

        let bu: any;
        for (bu of session.getBusinessUnits()) {
            if (bu.id === this.currentBusinessUnit) {
                while (bu.parent_id && (bu = buById[bu.parent_id])) {
                    expandedItems.push(bu.id);
                }
                break;
            }
        }

        return expandedItems;
    }
}


import { Inject, Prop, Provide, VModel } from 'vue-property-decorator';
import Spinner from '@/components/common/Spinner.vue';
import { Options, Vue } from 'vue-class-component';
import { ListViewModel } from '@/components/automation/flows/designer/ListViewModel';
import { reactive } from 'vue';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import { Field, FieldValue } from '@/components/automation/flows/designer/ConfigComponents/ObjectConfig.vue';
import ObjectConfigComponent from '@/components/automation/flows/designer/ConfigComponents/ObjectConfigComponent.vue';
import { ConfigComponent } from '@/components/automation/flows/designer/ConfigComponents/config-components';
import { BaseObject } from '@/components/automation/flows/definitions/FlowDefinitions';

@ConfigComponent('table-row')
@Options({
    components: {
        Spinner,
        'object-config-component': ObjectConfigComponent,
    },
    emits: ['dependency-change', 'input', 'contextmenu', 'custom-action'],
})
export default class ConfigTableRow extends Vue {
    @Inject() vm!: DesignerViewModel;

    @Prop()
    public object!: BaseObject;

    @Prop()
    protected field!: Field;

    @Prop()
    protected modelValue!: FieldValue;

    protected ready = false;

    created(): void {
        if (!this.modelValue.childValues) {
            this.modelValue.childValues = {};
        }

        for (let i in this.field.children) {
            if (!this.modelValue.childValues[i]) {
                this.modelValue.childValues[i] = {
                    type: 'value',
                    value: '',
                };
            }
        }

        this.ready = true;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    fieldChanged(field: any, fieldName: string): void {
        console.log('tableRow fieldChanged', field, fieldName);
        if (field.hasDependencies) {
            this.$emit('dependency-change', fieldName);
        }
    }
}


import { Options, Vue } from 'vue-class-component';
import AppTile from './AppTile.vue';
import { Prop } from 'vue-property-decorator';
import api from '@/shared/api';

type Action = { action: string; [key: string]: any };

type Format = {
    name: string;
    actions: Action[];
};

@Options({
    components: {
        AppTile,
    },
})
export default class ImageCropResizeSettings extends Vue {
    @Prop()
    protected app: undefined | any = undefined;

    protected ready = false;

    protected formats: Format[] = [];

    protected all_users = false;

    protected clients: { [key: string]: any }[] = [];
    protected customProps = [];
    protected error = '';

    protected previewImages: { name: string; src: string }[] = [];

    async created() {
        try {
            this.clients = await api.getSprinklrClients(this.app.id);
        } catch (e) {
            this.error = 'Error while retrieving Sprinklr Clients';
            return;
        }

        for (const client of this.clients) {
            try {
                client.auth = true;
                client.customProps = await api.getSprinklrCustomProperties(this.app.id, client.clientId);
            } catch (e) {
                client.auth = false;
                client.customProps = [];
            }
        }

        this.formats = this.app.settings && this.app.settings.formats ? this.app.settings.formats : [];
        this.all_users = this.app.settings && this.app.settings.all_users ? this.app.settings.all_users : false;
        this.ready = true;
    }

    public customPropData(action: any, client: any) {
        if (action.updateCustomProp && action.updateCustomProp[client.clientId]) {
            for (let customProp of client.customProps) {
                if (customProp.fieldName === action.updateCustomProp[client.clientId]) {
                    return customProp;
                }
            }
        }
        return {};
    }

    public getSettings() {
        return Object.assign(this.app.settings ?? {}, { formats: this.formats, all_users: this.all_users });
    }

    public addFormat() {
        this.formats.push({
            actions: [],
            name: '',
        });
    }

    public addAction(formatIdx: number, action: string) {
        this.formats[formatIdx].actions.push(this.defaultActionParams(action));
    }

    protected defaultActionParams(action: string) {
        return {
            [action]: { action },
            setcustomfield: {
                action,
                updateCustomProp: {},
                updateCustomPropValue: {},
            },
        }[action];
    }

    public moveAction(formatIdx: number, actionIdx: number, direction: 'up' | 'down') {
        const format = this.formats[formatIdx];
        const action = format.actions.splice(actionIdx, 1);
        let newIdx;
        if (direction === 'up') {
            newIdx = actionIdx > 0 ? actionIdx - 1 : format.actions.length;
        } else {
            newIdx = actionIdx < format.actions.length ? actionIdx + 1 : 0;
        }

        format.actions.splice(newIdx, 0, action[0]);
    }

    public startTest() {
        (this.$refs.fileUpload as HTMLElement).click();
    }

    public async executeTest() {
        const fileUpload = this.$refs.fileUpload as HTMLInputElement;
        console.log('executeTest', fileUpload.files);
        if (fileUpload.files) {
            const toBase64 = (file: File) =>
                new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result as string);
                    reader.onerror = (error) => reject(error);
                });

            this.previewImages.splice(0);

            for (const file of fileUpload.files) {
                console.log(file);
                const dataUri = await toBase64(file);
                const base64 = dataUri.replace(/^data:image\/\w+;base64,/, '');
                const prefix = dataUri.replace(/^(data:image\/\w+;base64,).*$/, '$1');
                console.log(file.name, base64, prefix);

                this.previewImages.push({ name: 'original', src: dataUri });

                for (const format of this.formats) {
                    const previewImage = { name: format.name, src: '' };
                    this.previewImages.push(previewImage);
                    api.getImageCropResizePreview(this.app.id, file.name, base64, format.actions).then((result) => {
                        previewImage.src = prefix + result.base64;
                        this.$forceUpdate();
                    });
                }
            }
        }

        fileUpload.value = '';
    }
}


import { Inject, Prop, Provide } from 'vue-property-decorator';
import Spinner from '@/components/common/Spinner.vue';
import { Options, Vue, VueBase, VueConstructor } from 'vue-class-component';
import { ListViewModel } from '@/components/automation/flows/designer/ListViewModel';
import { ComponentOptions, reactive } from 'vue';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import { Field, FieldValue } from '@/components/automation/flows/designer/ConfigComponents/ObjectConfig.vue';
import { configComponentTypes } from '@/components/automation/flows/designer/ConfigComponents/config-components';
import { BaseObject } from '@/components/automation/flows/definitions/FlowDefinitions';
import ContextMenu from '@/components/common/ContextMenu.vue';
import ContextMenuItem from '@/components/common/ContextMenuItem';

@Options({
    components: {
        ContextMenu,
        Spinner,
    },
    emits: ['dependency-change', 'input', 'helptext', 'custom-action'],
})
export default class ObjectConfigComponent extends Vue {
    @Inject() vm!: DesignerViewModel;

    protected ready = false;

    @Prop()
    protected fieldName!: string;

    @Prop()
    protected inRow: boolean = false;

    @Prop()
    public object!: BaseObject;

    @Prop()
    protected field!: Field;

    @Prop()
    protected value!: FieldValue;

    @Prop()
    protected wrapUsingRow: boolean = false;

    get fieldComponent() {
        const fieldType = this.field.type;
        return configComponentTypes[fieldType];
    }

    get isDelegatable() {
        const fieldType = this.field.type;
        switch (fieldType) {
            case 'message':
                return false;
            default:
                return true;
        }
    }

    get isDelegated() {
        return this.value.type === 'delegated';
    }

    toggleDelegated() {
        if (this.isDelegated) {
            this.value.type = 'value';
        } else {
            this.value.type = 'delegated';
        }
    }

    fieldChanged() {
        console.log('objectconfigcomponent fieldChanged', this.fieldName);
        if (this.field.hasDependencies) {
            this.$emit('dependency-change', this.fieldName);
        }
    }

    created() {
        this.ready = true;
    }

    protected contextMenu: {
        show: boolean;
        position: {
            left: number;
            top: number;
        };
        context: {
            object: BaseObject;
            field: Field;
        }[];
        element: HTMLElement | null;
        menus: ContextMenuItem[];
    } = {
        show: false,
        position: {
            left: 0,
            top: 0,
        },
        context: [],
        element: null,
        menus: [],
    };

    rightClick(event: MouseEvent): void {
        this.contextMenu.position.left = event.clientX;
        this.contextMenu.position.top = event.clientY;
        this.contextMenu.context = [
            {
                object: this.object,
                field: this.field,
            },
        ];
        this.contextMenu.element = event.target as HTMLElement;
        this.contextMenu.menus = [
            new ContextMenuItem({
                title: 'Delegate this configuration to Flow level',
                icon: 'fa-lock',
                callback: () => {
                    console.log('test click');
                    this.toggleDelegated();
                },
            }),
        ];
    }

    async contextMenuItemClick(
        item: ContextMenuItem,
        element?: HTMLElement | null,
        event?: MouseEvent,
        newWindow?: boolean,
    ): Promise<void> {
        console.log('item', item);
        if (item.action && item.actionCallback) {
            return await item.actionCallback(this.contextMenu.context, null, event, newWindow);
        } else if (item.groupCallback) {
            return await item.groupCallback(this.contextMenu.context, null, event, newWindow);
        } else if (item.callback) {
            return await item.callback(this.contextMenu.context[0], null, event, newWindow);
        } else {
            console.warn('No groupCallback or callback function set.');
        }
    }
}

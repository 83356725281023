import ViewModel from '@/components/automation/flows/designer/ViewModel';
import FlowStore from '@/components/automation/flows/designer/FlowStore';
import { Flow } from '@/components/automation/flows/designer/Flow';

class ListViewModel implements ViewModel {
    public loading = true;

    private flowStore = new FlowStore();

    public flows: Flow[] = [];

    init() {
        this.fetchAll();
    }

    fetchAll() {
        Promise.all([this.fetchFlows()]).then(() => {
            this.loading = false;
            console.log('done loading');
        });
    }

    async fetchFlows() {
        this.flows = await this.flowStore.fetch();
    }

    get byGroup() {
        return this.flows.reduce((byGroup: { [key: string]: Flow[] }, flow) => {
            if (!byGroup[flow.group]) {
                byGroup[flow.group] = [];
            }
            byGroup[flow.group].push(flow);
            return byGroup;
        }, {});
    }

    get groups() {
        return this.flows.map((flow) => flow.group).filter((value, index, self) => self.indexOf(value) === index);
    }

    async add(flow: Flow) {
        const id = await this.flowStore.add(flow);
        await this.fetchFlows();
        return id;
    }
}

export { ListViewModel };

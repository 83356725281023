import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["true-value", "false-value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-group", { 'd-flex': _ctx.inRow, 'align-items-center': _ctx.inRow, 'justify-content-center': _ctx.inRow, 'flex-row': _ctx.inRow }])
  }, [
    _createElementVNode("label", null, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        class: "form-check-input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.value) = $event)),
        "true-value": _ctx.field.params.trueValue ?? true,
        "false-value": _ctx.field.params.falseValue ?? false,
        "aria-describedby": "helpX",
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.field.hasDependencies && _ctx.$emit('dependency-change')))
      }, null, 40, _hoisted_1), [
        [_vModelCheckbox, _ctx.modelValue.value]
      ]),
      _createTextVNode(" " + _toDisplayString(_ctx.inRow ? '' : _ctx.field.label), 1)
    ])
  ], 2))
}
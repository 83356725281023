import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40153469"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "fa fa-list-ol"
}
const _hoisted_4 = {
  key: 0,
  class: "children"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_value_selector_tree = _resolveComponent("value-selector-tree", true)!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, optionIdx) => {
      return (_openBlock(), _createElementBlock("li", { key: optionIdx }, [
        _createElementVNode("div", {
          class: _normalizeClass(["item", { active: _ctx.modelValue === option.value }]),
          style: _normalizeStyle({ 'padding-left': _ctx.level * 30 + 'px' })
        }, [
          _createElementVNode("div", {
            class: "arrow",
            onClick: ($event: any) => (_ctx.expanded[optionIdx] = !_ctx.expanded[optionIdx])
          }, [
            (option.children && option.children.length > 0)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(["fa fa-fw", { 'fa-chevron-right': !_ctx.expanded[optionIdx], 'fa-chevron-down': _ctx.expanded[optionIdx] }])
                }, null, 2))
              : _createCommentVNode("", true)
          ], 8, _hoisted_1),
          _createElementVNode("div", {
            class: "label d-flex flex-row justify-content-between align-items-center",
            onClick: ($event: any) => (_ctx.selectItem(option.value))
          }, [
            _createTextVNode(_toDisplayString(option.label) + " ", 1),
            (option.collection)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3))
              : _createCommentVNode("", true)
          ], 8, _hoisted_2)
        ], 6),
        (_ctx.expanded[optionIdx])
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (option.children)
                ? (_openBlock(), _createBlock(_component_value_selector_tree, {
                    key: 0,
                    modelValue: _ctx.modelValue,
                    object: _ctx.object,
                    options: option.children,
                    "onUpdate:modelValue": _ctx.selectItem,
                    level: (_ctx.level ?? 0) + 1
                  }, null, 8, ["modelValue", "object", "options", "onUpdate:modelValue", "level"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}
import { RouteRecordRaw } from 'vue-router';

type settingsType<T> = Readonly<Record<string, unknown> & Partial<ContextMenuItem<T>>>;

export default class ContextMenuItem<T = any> {
    selectionMode: string = 'single';

    children!: Partial<ContextMenuItem<T>>[];
    callback?: (
        this: ContextMenuItem<T>,
        row: T,
        element?: HTMLElement | null,
        event?: MouseEvent,
        newWindow?: boolean,
    ) => any;
    groupCallback?: (
        this: ContextMenuItem<T>,
        row: T[],
        element?: HTMLElement | null,
        event?: MouseEvent,
        newWindow?: boolean,
    ) => any;
    actionCallback?: (
        this: ContextMenuItem<T>,
        row: T[],
        element?: HTMLElement | null,
        event?: MouseEvent,
        newWindow?: boolean,
    ) => any;

    action!: string | undefined;

    refresh!: boolean;

    header!: boolean | ((row: T) => boolean) | false;
    disabled!: boolean | ((row: T) => boolean);
    hidden!: boolean | ((row: T) => boolean);
    permission!: boolean | ((row: T) => Promise<boolean> | boolean);
    title!: string | ((row: T) => string);
    class!: string | ((row: T) => string) | ((row: T) => string) | undefined;
    route!: RouteRecordRaw | ((row: T) => RouteRecordRaw) | undefined;
    p!: ((dossierId: number) => Partial<T>) | ((row: T) => (dossierId: number) => Partial<T>) | undefined;
    icon: string | ((row: T) => string) | undefined;

    constructor(settings: settingsType<T>) {
        if (Array.isArray(settings.children) && settings.children.length) {
            for (let i = 0; i < settings.children.length; i++) {
                const contextMenu = settings.children[i];

                if (contextMenu instanceof ContextMenuItem) {
                    continue;
                }

                settings.children[i] = new ContextMenuItem<T>(contextMenu);
            }
        }
        Object.assign(this, defaultValues, settings);
    }
}

const toVueRoute = (url: RouteRecordRaw | ((row: any) => RouteRecordRaw), newWindow?: boolean | undefined) => {
    // eslint-disable-next-line no-undef
    // const router = globalThis.App.$router;
    // if (newWindow) {
    //     const routeData = router.resolve(url);
    //     window.open(routeData.href, '_blank');
    //     return;
    // }
    // return router.push(url);
};

const defaultValues: Partial<Readonly<ContextMenuItem>> = {
    selectionMode: 'single',

    children: [],
    route: undefined,
    action: '',

    disabled: false,
    hidden: false,
    permission: true,
    title: '',

    // default callback handles some basic functionality
    callback(row, element, event, newWindow) {
        if (this.route) {
            let route = this.route;
            if (typeof this.route === 'function') route = this.route(row);

            return toVueRoute(route, event?.ctrlKey || newWindow);
        }
    },
};

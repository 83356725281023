
import { Options, Vue } from 'vue-class-component';
import api from '@/shared/api';

interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    lastNamePrefix: string;
}

@Options({
    components: {},
    props: {},
})
export default class ManageUsers extends Vue {
    private users: User[] = [];
    private showCreateForm = true;
    private newUserEmail = '';
    private newUserFirstName = '';
    private newUserLastName = '';
    private newUserLastNamePrefix = '';
    private usersLoaded = false;
    private updateUserId: string | null = null;
    private updatedUserFirstName = '';
    private updatedUserLastName = '';
    private updatedUserLastNamePrefix = '';
    private updatedUserEmail = '';
    private deleteUserId: string | null = null;

    //Update

    private async updateUser(
        newId: string,
        firstName: string,
        lastName: string,
        email: string,
        lastNamePrefix: string,
    ) {
        // Update the user in the database
        await api.updateUser(newId, firstName, lastName, email, lastNamePrefix);
    }

    private confirmUpdateUser(id: string) {
        const user = this.users.find((u) => u.id === id);
        if (user) {
            user.firstName = this.updatedUserFirstName;
            user.lastName = this.updatedUserLastName;
            user.lastNamePrefix = this.updatedUserLastNamePrefix;
            user.email = this.updatedUserEmail;
            this.updateUserId = null;
            this.updatedUserFirstName = '';
            this.updatedUserLastName = '';
            this.updatedUserLastNamePrefix = '';
            this.updatedUserEmail = '';
        }
    }

    private startUpdateUser(id: string, firstName: string, lastName: string, email: string, lastNamePrefix: string) {
        this.updateUserId = id;
        this.updatedUserFirstName = firstName;
        this.updatedUserLastName = lastName;
        this.updatedUserEmail = email;
        this.newUserLastNamePrefix = lastNamePrefix;
    }

    private cancelUpdateUser() {
        this.updateUserId = null;
        this.updatedUserFirstName = '';
        this.updatedUserLastName = '';
        this.updatedUserEmail = '';
        this.newUserLastNamePrefix = '';
    }

    //Delete
    private async startDeleteUser(id: string) {
        this.deleteUserId = id;
    }
    private cancelDeleteUser() {
        this.deleteUserId = null;
    }

    private async confirmDeleteUser() {
        if (this.deleteUserId) {
            await this.deleteUser(this.deleteUserId);
            this.deleteUserId = null;
            this.loadUsers();
        }
    }

    private async deleteUser(id: string) {
        try {
            await api.deleteUser(id);
            this.users = this.users.filter((user) => user.id !== id);
        } catch (error) {
            console.error(`Error deleting user ${id}:`, error);
        }
    }

    //Get
    private async loadUsers() {
        try {
            this.users = await api.getUsers();
            this.usersLoaded = true;
        } catch (error) {
            console.error('Error loading users:', error);
        }
    }

    async mounted(): Promise<void>  {
        await this.loadUsers();
    }
}

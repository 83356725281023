
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class AppTile extends Vue {
    @Prop()
    protected appId = 0;

    @Prop()
    protected name = '';

    @Prop()
    protected icon = '';

    @Prop()
    protected title = '';

    @Prop()
    protected description = '';

    @Prop()
    protected smallDesc = '';

    @Prop()
    protected installed = false;

    @Prop()
    protected state = '';

    @Prop()
    protected stateClass = '';

    @Prop()
    protected labels = [];
}

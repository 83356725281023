
import { Options, Vue } from 'vue-class-component';
import { BaseObject, Connection, FlowConnectionType, FlowObjectData, ObjectDefinition, Waypoint } from '@/components/automation/flows/definitions/FlowDefinitions';
import { Inject } from 'vue-property-decorator';
import { Mounted } from '@/decorators/LifeCycle';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';

type Drag = {
    type?: string;
    idx?: number;
    confirmed: boolean;
    x: number;
    y: number;
    mouseX: number;
    mouseY: number;
    objectX: number;
    objectY: number;
    offsetX: number;
    offsetY: number;
    panX: number;
    panY: number;
    objectType?: FlowObjectData | undefined;
};

type ConnectionTypeSelector = {
    types: FlowConnectionType[];
    x: number;
    y: number;
    connection?: Connection;
};

@Options({
    props: {},
    emits: ['add-object', 'activate-object'],
})
export default class Canvas extends Vue {
    @Inject() vm!: DesignerViewModel;

    drag: Drag = {
        type: undefined,
        idx: undefined,
        confirmed: false,
        x: 0,
        y: 0,
        mouseX: -1,
        mouseY: -1,
        objectX: -1,
        objectY: -1,
        offsetX: -1,
        offsetY: -1,
        panX: -1,
        panY: -1,
    };

    bw = 300;
    bh = 120;
    hbw = 300 / 2;
    hbh = 120 / 2;

    width: number = 1000;
    height: number = 600;
    panX: number = 0;
    panY: number = 0;
    zoomOriginX: number = 0;
    zoomOriginY: number = 0;
    zoom: number = 1;

    overlayText: { [key: string]: string } = {
        add: '&#x002b;',
        edit: '&#xf303;',
        start: '&#xe0b7;',
        lookup: '&#xf002;',
    };

    contextmenu: { x: number; y: number; items: any[] } = {
        x: 0,
        y: 0,
        items: [],
    };

    connectionTypeSelector: ConnectionTypeSelector = {
        types: [],
        x: 0,
        y: 0,
        connection: undefined,
    };

    @Mounted
    updateCanvasDimensions() {
        console.log('updateCanvasDimensions');
        // TODO: Fix this
        window.setTimeout(() => {
            this.height = (this.$el as HTMLElement).clientHeight;
            this.width = (this.$el as HTMLElement).clientWidth;

            this.zoomOriginX = this.width / 2;
            this.zoomOriginY = this.height / 2;
        }, 100);
    }

    hasConnections(object: BaseObject) {
        return this.vm.flow.connections.some((c) => c.from === object);
    }

    hasOneConnection(object: BaseObject) {
        return this.vm.flow.connections.filter((c) => c.from === object).length === 1;
    }

    onDragStart(e: MouseEvent, type: string, idx: number) {
        console.log('onDragStart', type, idx);
        if (this.vm.readonly) {
            switch (type) {
                case 'newconnection':
                case 'object':
                case 'waypoint':
                    return;
            }
        }

        this.drag.type = type;
        this.drag.idx = idx;
        this.drag.mouseX = e.pageX;
        this.drag.mouseY = e.pageY;

        switch (this.drag.type) {
            case 'newconnection': {
                let object = this.vm.flow.objects[this.drag.idx];
                this.drag.offsetX = e.offsetX - object.x;
                this.drag.offsetY = e.offsetY - object.y;

                console.log(e.offsetX, e.offsetY, object.x, object.y);

                break;
            }
            case 'object': {
                let object = this.vm.flow.objects[this.drag.idx];
                this.drag.objectX = object.x;
                this.drag.objectY = object.y;
                break;
            }
            case 'canvas': {
                this.vm.selected = false;

                this.drag.panX = this.panX;
                this.drag.panY = this.panY;
                break;
            }
            case 'waypoint': {
                let cIdx = Math.floor(this.drag.idx / 10000);
                let wIdx = this.drag.idx - cIdx * 10000;
                console.log('waypoint', cIdx, wIdx);
                let waypoint = this.vm.flow.connections[cIdx].waypoints[wIdx];
                console.log('waypoint', cIdx, wIdx, waypoint);
                this.drag.objectX = waypoint.x;
                this.drag.objectY = waypoint.y;
                break;
            }
        }
    }

    onDragOver(e: MouseEvent) {
        if (!this.drag.type || this.drag.idx === undefined) return;

        if (Math.abs(e.pageX - this.drag.mouseX) > 10 || Math.abs(e.pageY - this.drag.mouseY)) {
            this.drag.confirmed = true;
        }

        if (this.drag.confirmed) {
            switch (this.drag.type) {
                case 'newconnection': {
                    let object = this.vm.flow.objects[this.drag.idx];

                    this.drag.objectX = e.pageX - this.drag.mouseX + object.x + this.drag.offsetX - this.panX;
                    this.drag.objectY = e.pageY - this.drag.mouseY + object.y + this.drag.offsetY - this.panY;
                    break;
                }
                case 'object': {
                    let object = this.vm.flow.objects[this.drag.idx];
                    object.x = this.drag.objectX + (e.pageX - this.drag.mouseX) / this.zoom;
                    object.y = this.drag.objectY + (e.pageY - this.drag.mouseY) / this.zoom;
                    break;
                }
                case 'canvas': {
                    this.panX = this.drag.panX + (e.pageX - this.drag.mouseX) / this.zoom;
                    this.panY = this.drag.panY + (e.pageY - this.drag.mouseY) / this.zoom;
                    break;
                }
                case 'waypoint': {
                    let cIdx = Math.floor(this.drag.idx / 10000);
                    let wIdx = this.drag.idx - cIdx * 10000;
                    let waypoint = this.vm.flow.connections[cIdx].waypoints[wIdx];
                    waypoint.x = this.drag.objectX + (e.pageX - this.drag.mouseX) / this.zoom;
                    waypoint.y = this.drag.objectY + (e.pageY - this.drag.mouseY) / this.zoom;

                    break;
                }
            }
        }
    }

    onDragStop(e: MouseEvent) {
        if (!this.drag.type || this.drag.idx === undefined) return;

        switch (this.drag.type) {
            case 'newconnection': {
                if ((e.target as HTMLElement).dataset.idx) {
                    let fromObject = this.vm.flow.objects[this.drag.idx] as BaseObject;
                    let targetIdx = (e.target as HTMLElement).dataset.idx as unknown as number;
                    let object = this.vm.flow.objects[targetIdx];

                    fromObject
                        .remote()
                        .getAvailableConnectionTypes()
                        .then((connectionTypes) => {
                            // let connectionTypes = ['continue', '1', '2'];
                            if (connectionTypes.length > 0) {
                                let connection;

                                this.vm.flow.connections.push(
                                    (connection = Object.assign(new Connection(), {
                                        from: fromObject,
                                        to: object,
                                        type: connectionTypes.length === 1 ? connectionTypes[0] : null,
                                        waypoints: [],
                                    })),
                                );

                                // TODO: this.checkFlowStatus();

                                if (connectionTypes.length > 1) {
                                    let offsetX: number = 0,
                                        offsetY: number = 0,
                                        p: null | HTMLElement = this.$el as HTMLElement;
                                    while ((() => (p = p ? (p.offsetParent as HTMLElement) : null))()) {
                                        if (p) {
                                            offsetX += p.offsetLeft;
                                            offsetY += p.offsetTop;
                                        }
                                    }

                                    this.connectionTypeSelector.types = connectionTypes;
                                    this.connectionTypeSelector.x = e.clientX - offsetX;
                                    this.connectionTypeSelector.y = e.clientY - offsetY;
                                    this.connectionTypeSelector.connection = connection;
                                }
                                console.log(this.connectionTypeSelector);
                            }
                        });
                }
                break;
            }
            case 'object': {
                let object = this.vm.flow.objects[this.drag.idx];
                object.x = this.snap(object.x);
                object.y = this.snap(object.y);
                break;
            }
            case 'canvas': {
                this.panX = this.snap(this.panX);
                this.panY = this.snap(this.panY);
                break;
            }
            case 'waypoint': {
                let cIdx = Math.floor(this.drag.idx / 10000);
                let wIdx = this.drag.idx - cIdx * 10000;
                let waypoint = this.vm.flow.connections[cIdx].waypoints[wIdx];
                waypoint.x = this.snap(waypoint.x);
                waypoint.y = this.snap(waypoint.y);
                break;
            }
        }

        this.drag.idx = this.drag.type = undefined;
        this.drag.confirmed = false;
        this.vm.flow.dirty = true;
    }

    onConnectionMouseDown(e: MouseEvent, cIdx: number, insertPos: number) {
        let c = this.vm.flow.connections[cIdx];
        c.waypoints.splice(insertPos, 0, Object.assign(new Waypoint(), { x: e.offsetX - this.panX, y: e.offsetY - this.panY }));

        this.onDragStart(e, 'waypoint', cIdx * 10000 + insertPos);
    }

    onWaypointDblClick(e: MouseEvent, cIdx: number, wIdx: number) {
        let c = this.vm.flow.connections[cIdx];
        c.waypoints.splice(wIdx, 1);
        this.vm.flow.dirty = true;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onScroll(event: WheelEvent): void {
        console.log(event);
        this.zoomOriginX = event.offsetX;
        this.zoomOriginY = event.offsetY;
        this.zoom -= event.deltaY / 1000;
        if (this.zoom < 0.1) {
            this.zoom = 0.1;
        }
    }

    snap(n: number) {
        return Math.round(n / 10) * 10;
    }

    translateMouseCoords(coords: { x: number; y: number }) {
        return {
            x: this.snap(-60 - ((this.width / 2 - coords.x) / this.zoom - this.width / 2) - this.panX / this.zoom), // - this.libraryDrag.objectX / this.zoom,
            y: this.snap(-60 - ((this.height / 2 - coords.y) / this.zoom - this.height / 2) - this.panY / this.zoom), // - this.libraryDrag.objectY / this.zoom,
        };
    }

    arrowHead(c: Connection) {
        function checkLineIntersection(
            line1StartX: number,
            line1StartY: number,
            line1EndX: number,
            line1EndY: number,
            line2StartX: number,
            line2StartY: number,
            line2EndX: number,
            line2EndY: number,
        ) {
            // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite) and booleans for whether line segment 1 or line segment 2 contain the point
            let denominator,
                a,
                b,
                numerator1,
                numerator2,
                result: { x: number | null; y: number | null; onLine1: boolean; onLine2: boolean } = {
                    x: null,
                    y: null,
                    onLine1: false,
                    onLine2: false,
                };
            denominator = (line2EndY - line2StartY) * (line1EndX - line1StartX) - (line2EndX - line2StartX) * (line1EndY - line1StartY);
            if (denominator == 0) {
                return result;
            }
            a = line1StartY - line2StartY;
            b = line1StartX - line2StartX;
            numerator1 = (line2EndX - line2StartX) * a - (line2EndY - line2StartY) * b;
            numerator2 = (line1EndX - line1StartX) * a - (line1EndY - line1StartY) * b;
            a = numerator1 / denominator;
            b = numerator2 / denominator;

            // if we cast these lines infinitely in both directions, they intersect here:
            result.x = line1StartX + a * (line1EndX - line1StartX);
            result.y = line1StartY + a * (line1EndY - line1StartY);

            // if line1 is a segment and line2 is infinite, they intersect if:
            if (a > 0 && a < 1) {
                result.onLine1 = true;
            }
            // if line2 is a segment and line1 is infinite, they intersect if:
            if (b > 0 && b < 1) {
                result.onLine2 = true;
            }
            // if line1 and line2 are segments, they intersect if both of the above are true
            return result;
        }

        function lineBox(x1: number, y1: number, x2: number, y2: number, xb: number, yb: number, wb: number, hb: number) {
            let center = { x: xb + wb / 2, y: yb + hb / 2 };
            let lines = [
                {
                    line: [x1, y1, x2, y2, xb, yb, xb + wb, yb],
                    o: (i: { x: number }) => center.x - i.x,
                    a: hb / 2,
                    alpha_multiply: 1,
                    alpha_add: 0,
                    intersect: { onLine1: false, onLine2: false, alpha: 0, x: 0, y: 0 },
                },
                {
                    line: [x1, y1, x2, y2, xb + wb, yb, xb + wb, yb + hb],
                    o: (i: { y: number }) => center.y - i.y,
                    a: wb / 2,
                    alpha_multiply: 1,
                    alpha_add: -0.5 * Math.PI,
                    intersect: { onLine1: false, onLine2: false, alpha: 0, x: 0, y: 0 },
                },
                {
                    line: [x1, y1, x2, y2, xb, yb + hb, xb + wb, yb + hb],
                    o: (i: { x: number }) => center.x - i.x,
                    a: hb / 2,
                    alpha_multiply: -1,
                    alpha_add: Math.PI,
                    intersect: { onLine1: false, onLine2: false, alpha: 0, x: 0, y: 0 },
                },
                {
                    line: [x1, y1, x2, y2, xb, yb, xb, yb + hb],
                    o: (i: { y: number }) => center.y - i.y,
                    a: wb / 2,
                    alpha_multiply: -1,
                    alpha_add: 0.5 * Math.PI,
                    intersect: { onLine1: false, onLine2: false, alpha: 0, x: 0, y: 0 },
                },
            ];
            for (let l in lines) {
                let line = lines[l];
                // @ts-ignore
                line.intersect = checkLineIntersection(...line.line);
                if (line.intersect.onLine1 && line.intersect.onLine2) {
                    line.intersect.alpha = line.alpha_multiply * Math.atan(line.o(line.intersect) / line.a) + line.alpha_add;
                    return line.intersect;
                }
            }
            for (let l in lines) {
                let line = lines[l];
                // @ts-ignore
                if (line.intersect.onLine1 || line.intersect.onLine2) {
                    line.intersect.alpha = line.alpha_multiply * Math.atan(line.o(line.intersect) / line.a) + line.alpha_add;
                    return line.intersect;
                }
            }

            return false;
        }

        let line = {
            start: { x: c.from.x + 150, y: c.from.y + 60 },
            end: { x: c.to.x + 150, y: c.to.y + 60 },
        };
        if (c.waypoints.length > 0) {
            line.start.x = c.waypoints[c.waypoints.length - 1].x;
            line.start.y = c.waypoints[c.waypoints.length - 1].y;
        }

        let box = {
            x: c.to.x,
            y: c.to.y,
            w: 300,
            h: 120,
        };

        let intersection = lineBox(line.start.x, line.start.y, line.end.x, line.end.y, box.x, box.y, box.w, box.h);

        return intersection;
    }

    showObjectContextMenu(e: MouseEvent, obj: BaseObject, idx: number) {
        let offsetX: number = 0,
            offsetY: number = 0,
            p: null | HTMLElement = this.$refs.canvas as HTMLElement;
        while ((() => (p = p ? (p.offsetParent as HTMLElement) : null))()) {
            if (p) {
                offsetX += p.offsetLeft;
                offsetY += p.offsetTop;
            }
        }

        console.log('contextmenu');
        this.contextmenu = {
            x: e.pageX - offsetX,
            y: e.pageY - offsetY,
            items: [
                {
                    label: 'View logs',
                    fn: () => {
                        this.$emit('view-object-logs', idx);
                    },
                },
                {
                    label: 'Properties',
                    fn: () => {
                        this.$emit('activate-object', idx);
                    },
                },
            ],
        };
    }
}

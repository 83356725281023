
import { Component, Provide, Prop, Watch, ProvideReactive } from 'vue-property-decorator';
import { BaseObject, Connection, FlowConnectionType, FlowObjectData, ObjectDefinition, Waypoint } from '@/components/automation/flows/definitions/FlowDefinitions';
import { BeforeCreate, Created, Destroyed, Mounted } from '@/decorators/LifeCycle';
import ObjectConfigWindow from '@/components/automation/flows/designer/ObjectConfigWindow.vue';
import { Options, Vue, VueConstructor } from 'vue-class-component';
import { Flow } from '@/components/automation/flows/designer/Flow';
import Canvas from '@/components/automation/flows/designer/Canvas.vue';
import Toolbar from '@/components/automation/flows/designer/Toolbar.vue';
import ObjectLibrary from '@/components/automation/flows/designer/ObjectLibrary.vue';
import { ListViewModel } from '@/components/automation/flows/designer/ListViewModel';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import AddObjectWindow from '@/components/automation/flows/designer/AddObjectWindow.vue';
import ObjectLogsWindow from '@/components/automation/flows/designer/ObjectLogsWindow.vue';
import FlowStatus from '@/components/automation/flows/designer/FlowStatus.vue';

type Drag = {
    type?: string;
    idx?: number;
    confirmed: boolean;
    x: number;
    y: number;
    mouseX: number;
    mouseY: number;
    objectX: number;
    objectY: number;
    offsetX: number;
    offsetY: number;
    panX: number;
    panY: number;
    objectType?: FlowObjectData | undefined;
};
//
// type ConnectionTypeSelector = {
//     types: string[],
//     x: number,
//     y: number,
//     connection?: Connection
// }

@Options({
    components: {
        ObjectLibrary,
        ObjectConfigWindow,
        AddObjectWindow,
        'designer-canvas': Canvas,
        'object-logs-window': ObjectLogsWindow,
        FlowStatus,
        Toolbar,
    },
})
export default class Designer extends Vue {
    @Provide() vm = new DesignerViewModel();

    @Created
    async init(): Promise<void> {
        this.vm.init();
        this.vm.ready = true;
        // this.flow = new Flow();
        this.vm.flow.id = this.$route.params.flowId as string;

        await this.vm.flow.load();
        await this.showStartDialog('start');
        await this.updateAllStatuses();

        window.addEventListener('keydown', this.onKeyDown);
    }

    @Destroyed
    uninitialize(): void {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown(e: KeyboardEvent): void {
        if (e.target !== document.body) {
            return;
        }
        console.log('keydown', e.target);

        if (e.key === 'Escape') {
            this.vm.selected = false;
        } else if (e.key === 'Delete') {
            this.removeSelectedObject();
        }
    }

    @Watch('$route', { deep: true })
    async loadFlow(): Promise<void> {
        if (this.$route.params.flowId) {
            this.vm.flow.id = this.$route.params.flowId as string;
            await this.vm.flow.load();
            await this.showStartDialog('start');
            await this.updateAllStatuses();
        }
    }

    async updateAllStatuses(): Promise<void> {
        for (const object of this.vm.flow.objects) {
            await object.updateStatus();
        }
    }

    addObjectWindow: false | { show: boolean; type?: string; params: any } = false;

    async showStartDialog(type: string, params: any = {}): Promise<void> {
        if (type === 'add' || this.vm.flow.objects.length === 0) {
            this.addObjectWindow = {
                show: true,
                type,
                params,
            };
        }
    }

    async addObject(params: { objectType: FlowObjectData; connectionType: FlowConnectionType }): Promise<void> {
        if (this.addObjectWindow) {
            const objectType = params.objectType as FlowObjectData;
            const connectionType = params.connectionType as FlowConnectionType;

            //@ts-ignore
            const canvas = this.$refs.canvas as Canvas;

            let object: BaseObject = Object.assign(
                new BaseObject(),
                {
                    //@ts-ignore
                    title: objectType.title,
                    //@ts-ignore
                    icon: objectType.icon,
                    //@ts-ignore
                    type: objectType.type,
                    //@ts-ignore
                    category: objectType.category,
                    //@ts-ignore
                    subCategory: objectType.subCategory,
                    flow: this.vm.flow,
                },
                this.addObjectWindow.params && this.addObjectWindow.params.coords
                    ? this.addObjectWindow.params.coords
                    : {
                          x: (canvas.width - 300) / 2,
                          y: 100,
                      },
            );

            this.vm.flow.objects.push(object);

            if (this.addObjectWindow.params && this.addObjectWindow.params.previousObject) {
                this.vm.flow.connections.push(
                    Object.assign(new Connection(), {
                        from: this.addObjectWindow.params.previousObject,
                        to: object,
                        type: connectionType,
                        waypoints: [],
                    }),
                );

                this.editconfig = object;
            } else {
                this.editconfig = object;
            }
        }
        this.addObjectWindow = false;
    }

    libraryDrag: Drag = {
        objectType: undefined,
        confirmed: false,
        x: 0,
        y: 0,
        offsetX: 0,
        offsetY: 0,
        mouseX: -1,
        mouseY: -1,
        objectX: -1,
        objectY: -1,
        panX: -1,
        panY: -1,
    };

    // @Prop() private msg!: string;
    // @Provide()
    // getFlowJSON() {
    //     return JSON.stringify(this.flow);
    // }
    //
    // flow!: Flow;
    // objectTypes!: {[key: string]: Collection<ObjectDefinition>};
    // readonly: boolean = true;
    // saving: boolean = false;
    editconfig: null | BaseObject = null;
    viewlogs: null | BaseObject = null;
    // contextmenu: {x: number, y: number, items: any[]} = {
    //     x: 0,
    //     y: 0,
    //     items: [
    //     ]
    // };
    //
    //
    async onSaveObject(e: any): Promise<void> {
        console.log('onSaveObject', e);
        if (this.editconfig) {
            this.vm.flow.dirty = true;
            this.editconfig.config = e.config;
            this.editconfig.description = e.description;

            await this.editconfig.updateStatus();

            this.editconfig = null;
        }
        // this.checkFlowStatus();
    }

    async onCloseObjectWindow(): Promise<void> {
        if (this.editconfig) {
            await this.editconfig.updateStatus();
            this.editconfig = null;
        }
        // this.checkFlowStatus();
    }
    //
    // showObjectContextMenu(e: MouseEvent, obj: BaseObject, idx: number) {
    //     let offsetX: number = 0, offsetY: number = 0, p:null|HTMLElement = this.$refs.wrapper as HTMLElement;
    //     while((() => p = (p ? p.offsetParent as HTMLElement: null))()) {
    //         if (p) {
    //             offsetX += p.offsetLeft;
    //             offsetY += p.offsetTop;
    //         }
    //     }
    //
    //     console.log('contextmenu');
    //     this.contextmenu = {
    //         x: e.clientX - offsetX,
    //         y: e.clientY - offsetY,
    //         items: [
    //             {
    //                 label: 'View logs',
    //                 fn: () => {
    //                     console.log('view logs');
    //                 }
    //             },
    //             {
    //                 label: 'Properties',
    //                 fn: () => {
    //                     this.onActivateObject(idx);
    //                 }
    //             },
    //         ]
    //     };
    // }
    //
    // checkFlowStatus() {
    //     // TODO:
    //     // HTTP.post(
    //     //     ['flow', 'checkStatusAndDescription'],
    //     //     JSON.stringify(this.flow),
    //     //     {
    //     //         params: {
    //     //             id: this.flow.id
    //     //         }
    //     //     }
    //     // ).then(response => {
    //     //     this.flow.objects.forEach((object) => {
    //     //         let info = response.data[object.id];
    //     //         if (typeof info === 'object') {
    //     //             object.status      = info.status;
    //     //             object.description = info.description;
    //     //         }
    //     //     });
    //     // })
    // }
    //
    // @Mounted
    // fetchFlow() {
    //     this.flow = new Flow();
    //     console.log('fetchFlow', this.flow);
    //     this.ready = true;
    //     // TODO
    //     // HTTP.get(
    //     //     ['rest', 'flow', 'get'],
    //     //     null,
    //     //     {
    //     //         params: {
    //     //             flowId: this.$route.params.id
    //     //         }
    //     //     }
    //     // ).then((response: any) => {
    //     //     console.log(response);
    //     //     const flowData = response.data.data;
    //     //     const flow = new Flow();
    //     //     let maxX: number|boolean = false,
    //     //         minX: number|boolean = false,
    //     //         maxY: number|boolean = false,
    //     //         minY: number|boolean = false;
    //     //
    //     //     const populateObj = (obj: {from: bigint, to: bigint, waypoints: any}) => {
    //     //         if (obj.from) {
    //     //             obj.from = objects.find((o) => {
    //     //                 return o.id === obj.from;
    //     //             })
    //     //         }
    //     //         if (obj.to) {
    //     //             obj.to = objects.find((o) => {
    //     //                 return o.id === obj.to;
    //     //             })
    //     //         }
    //     //         obj.waypoints = Collection.from(obj.waypoints.map((w: Partial<Waypoint>) => Object.assign(new Waypoint,w)));
    //     //         return obj;
    //     //     };
    //     //
    //     //     // Convert all BaseObjects to a collection
    //     //     const objects: Collection<BaseObject> = Collection.from(flowData.objects.map((obj: Partial<BaseObject>) => {
    //     //         if (maxX === false || (obj.x as number) > maxX) {
    //     //             maxX = (obj.x as number);
    //     //         }
    //     //         if (minX === false || (obj.x as number) < minX) {
    //     //             minX = (obj.x as number);
    //     //         }
    //     //         if (maxY === false || (obj.y as number) > maxY) {
    //     //             maxY = (obj.y as number);
    //     //         }
    //     //         if (minY === false || (obj.y as number) < minY) {
    //     //             minY = (obj.y as number);
    //     //         }
    //     //         return Object.assign(new BaseObject,{flow},obj);
    //     //     }));
    //     //
    //     //     const connections: Collection<Connection> = Collection.from(flowData.connections.map((obj: {from:bigint, to:bigint, waypoints:any}) => Object.assign(new Connection, populateObj(obj))));
    //     //
    //     //     Object.assign(flow,{
    //     //         ...flowData,
    //     //         objects,
    //     //         connections
    //     //     });
    //     //
    //     //     this.flow = flow as Flow;
    //     //     console.log(this.flow);
    //     //     this.$forceUpdate();
    //     //     this.ready = true;
    //     //
    //     //     if (maxX !== false && minX !== false && maxY !== false && minY !== false){
    //     //         let flowWidth = maxX - minX;
    //     //         let flowHeight = maxY - minY;
    //     //
    //     //         this.panX = (this.width - 300 - flowWidth) / 2;
    //     //         this.panY = (this.height - flowHeight) / 2;
    //     //     }
    //     // })
    // }
    //
    //
    // save(this: Designer) {
    //     if (!this.readonly) {
    //         this.saving = true;
    //
    //         console.log(this.flow);
    //         console.log(JSON.stringify(this.flow));
    //         // TODO
    //         // HTTP.put(
    //         //     ['rest', 'flow', 'put'],
    //         //     JSON.stringify(this.flow),
    //         //     {
    //         //         params: {
    //         //             id: this.flow.id
    //         //         },
    //         //     }
    //         // ).then((response: any) => {
    //         //     console.log(response);
    //         //     this.fetchFlow();
    //         //
    //         //     this.saving = false;
    //         // })
    //     }
    //
    // }
    removeSelectedObject(this: Designer) {
        if (this.vm.selected) {
            this.vm.flow.dirty = true;

            const flow = this.vm.flow;
            const selected = this.vm.selected;

            if (this.vm.selected instanceof BaseObject) {
                const idx = flow.objects.findIndex((o: any) => selected == o);
                if (idx >= 0) {
                    flow.objects.splice(idx, 1);
                }
                const removeConnections: number[] = [];
                flow.connections.forEach((c: any, idx: number) => {
                    if (c.from === selected || c.to === selected) {
                        removeConnections.push(idx);
                    }
                });
                while (removeConnections.length) {
                    flow.connections.splice(removeConnections.pop() as number, 1);
                }
            }
            if (selected instanceof Connection) {
                const idx = flow.connections.findIndex((o: any) => selected == o);
                if (idx >= 0) {
                    flow.connections.splice(idx, 1);
                }
            }
            this.vm.selected = false;
        }
    }
    //
    // protected ready: boolean = false;
    // protected libraryReady: boolean = false;
    //
    // width: number = 1000;
    // height: number = 600;
    // panX: number = 0;
    // panY: number = 0;
    // zoomOriginX: number = 0;
    // zoomOriginY: number = 0;
    // zoom: number = 1;
    //
    // connectionTypeSelector: ConnectionTypeSelector = {
    //     types: [],
    //     x: 0,
    //     y: 0,
    //     connection: undefined
    // };
    //
    // drag: Drag = {
    //     type: undefined,
    //     idx: undefined,
    //     confirmed: false,
    //     x: 0,
    //     y: 0,
    //     mouseX: -1,
    //     mouseY: -1,
    //     objectX: -1,
    //     objectY: -1,
    //     offsetX: -1,
    //     offsetY: -1,
    //     panX: -1,
    //     panY: -1,
    // };
    //
    // constructor(...args: any) {
    //     super(...args);
    //     console.log(this.$parent)
    // }
    //
    // @Mounted
    // updateCanvasDimensions() {
    //     console.log('updateCanvasDimensions');
    //     // TODO: Fix this
    //     window.setTimeout(() => {
    //         this.height = (this.$refs.canvasContainer as HTMLElement).clientHeight;
    //         this.width = (this.$refs.canvasContainer as HTMLElement).clientWidth;
    //
    //         this.zoomOriginX = this.width / 2;
    //         this.zoomOriginY = this.height / 2;
    //     }, 100);
    // }
    //
    //
    //

    onActivateObject(this: Designer, idx: number) {
        console.log(idx);
        this.editconfig = this.vm.flow.objects[idx];
    }

    onViewObjectLogs(this: Designer, idx: number) {
        console.log(idx);
        // this.viewlogs = this.vm.flow.objects[idx];
        this.$router.push({
            name: 'automation-flows-edit-object-log',
            params: { objectId: this.vm.flow.objects[idx].id },
        });
    }

    onViewObjectLogsFromConfig(this: Designer) {
        //
        // console.log(idx);
        // this.viewlogs = this.editconfig;
        if (this.editconfig) {
            this.$router.push({
                name: 'automation-flows-edit-object-log',
                params: { objectId: this.editconfig.id },
            });
        }

        this.editconfig = null;
    }

    //
    //
    //
    //
    //

    //
    //
    // @Mounted
    // fetchObjectTypes(this: Designer) {
    //     this.objectTypes = {
    //         'flow': Collection.from([
    //             {
    //                 type: 'generic.Condition',
    //                 icon: 'condition',
    //                 title: 'Condition',
    //                 category: 'flow',
    //             },
    //             {
    //                 type: 'generic.Timer',
    //                 icon: 'timer',
    //                 title: 'Timer',
    //                 category: 'flow',
    //             }
    //         ].map((obj: Partial<ObjectDefinition>) => Object.assign(new ObjectDefinition, obj)))
    //     };
    //     this.libraryReady = true;
    //     console.log('fetchObjectTypes', this.objectTypes);
    //
    //     // TODO
    //     // HTTP.get(
    //     //     ['rest', 'flow-object-type', 'get']
    //     // ).then(response => {
    //     //     console.log(response);
    //     //     const objectTypesData = response.data.data;
    //     //     const objectTypesMap:{[key: string]: Collection<ObjectDefinition>} = {};
    //     //
    //     //     // Convert all BaseObjects to a collection
    //     //     for (let category in objectTypesData) {
    //     //         objectTypesMap[category] = Collection.from(objectTypesData[category].map((obj: Partial<ObjectDefinition>) => Object.assign(new ObjectDefinition, obj)));
    //     //         // const objects: Collection<ObjectDefinition> = Collection.from(objectTypesData.map((obj: Partial<ObjectDefinition>) => Object.assign(new ObjectDefinition, obj)));
    //     //     }
    //     //
    //     //     this.objectTypes = objectTypesMap as {[key: string]: Collection<ObjectDefinition>};
    //     //     this.libraryReady = true;
    //     //     console.log(this.objectTypes);
    //     // })
    // }
    //
    onLibraryObjectMouseDown(e: MouseEvent, objectType: FlowObjectData) {
        this.libraryDrag.objectType = objectType;
        this.libraryDrag.confirmed = true;
        this.libraryDrag.objectX = 70;
        this.libraryDrag.objectY = 120;
        this.libraryDrag.offsetX = 0;
        this.libraryDrag.offsetY = 0;
        this.libraryDrag.mouseX = e.pageX;
        this.libraryDrag.mouseY = e.pageY;

        let p: null | HTMLElement = this.$refs.wrapper as HTMLElement;
        // eslint-disable-next-line no-cond-assign
        while ((p = p ? (p.offsetParent as HTMLElement) : null)) {
            console.log(p, p.offsetLeft, p.offsetTop);
            this.libraryDrag.offsetX += p.offsetLeft;
            this.libraryDrag.offsetY += p.offsetTop;
        }

        this.libraryDrag.x = e.pageX - this.libraryDrag.offsetX - this.libraryDrag.objectX;
        this.libraryDrag.y = e.pageY - this.libraryDrag.offsetY - this.libraryDrag.objectY;
    }

    onLibraryDragOver(e: MouseEvent) {
        if (this.libraryDrag.confirmed) {
            this.libraryDrag.x = e.pageX - this.libraryDrag.offsetX - this.libraryDrag.objectX;
            this.libraryDrag.y = e.pageY - this.libraryDrag.offsetY - this.libraryDrag.objectY;
        }
    }

    async onLibraryDragStop(e: MouseEvent): Promise<void> {
        if (this.libraryDrag.confirmed) {
            this.vm.flow.dirty = true;
            this.libraryDrag.confirmed = false;

            //@ts-ignore
            const canvas = this.$refs.canvas as Canvas;
            const coords = canvas.translateMouseCoords({ x: e.offsetX, y: e.offsetY });
            const object = Object.assign(
                new BaseObject(),
                {
                    //@ts-ignore
                    title: this.libraryDrag.objectType.title,
                    //@ts-ignore
                    icon: this.libraryDrag.objectType.icon,
                    //@ts-ignore
                    type: this.libraryDrag.objectType.type,
                    //@ts-ignore
                    category: this.libraryDrag.objectType.category,
                    //@ts-ignore
                    subCategory: this.libraryDrag.objectType.subCategory,
                    flow: this.vm.flow,
                },
                coords,
            );
            this.vm.flow.objects.push(object);

            await object.updateStatus();
            this.$forceUpdate();
            canvas.$forceUpdate();
            // console.log(e);
            // this.checkFlowStatus();
        }
    }
}

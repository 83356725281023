
import { Options, Vue } from 'vue-class-component';
import AppTile from './AppTile.vue';
import api from '@/shared/api';
import ExpiredAssetsSettings from '@/components/apps/ExpiredAssetsSettings.vue';
import { Provide } from 'vue-property-decorator';
import UserGroupShareSettings from '@/components/apps/UserGroupShareSettings.vue';
import ImageCropResizeSettings from '@/components/apps/ImageCropResizeSettings.vue';
import AssetUnzipSettings from '@/components/apps/AssetUnzipSettings.vue';
import BulkMessageSettings from '@/components/apps/BulkMessageSettings.vue';
import getAppDefinitions, {AppDefinitions} from "@/shared/apps";

@Options({
    components: {
        AppTile,
    },
})
export default class AppSettings extends Vue {
    protected availableApps: AppDefinitions = {};

    @Provide()
    protected app: undefined | any = undefined;
    protected settingsComponent: any | null = null;
    protected removeModal = false;

    protected hasSettings = true;

    async created() {
        this.availableApps = await getAppDefinitions();
    }

    async mounted() {
        const appId = parseInt(this.$route.params.appId as string);

        this.app = await api.getAppSettings(appId);

        switch (this.app.app_name) {
            case 'expired-assets':
                this.settingsComponent = ExpiredAssetsSettings;
                break;
            case 'usergroup-share':
                this.settingsComponent = UserGroupShareSettings;
                break;
            case 'image-crop-resize':
                this.settingsComponent = ImageCropResizeSettings;
                break;
            case 'asset-unzip':
                this.settingsComponent = AssetUnzipSettings;
                break;
            case 'bulk-message':
                this.settingsComponent = BulkMessageSettings;
                this.hasSettings = false;
                break;
        }
    }

    async saveSettings(close: boolean) {
        const appId = parseInt(this.$route.params.appId as string);
        const settings = (this.$refs.settingsComp as any).getSettings();
        await api.saveAppSettings(appId, settings);

        if (close) {
            this.$router.push({ name: 'apps-installed' });
        }
    }

    async removeApp() {
        this.removeModal = true;
    }
    async removeAppConfirm() {
        const appId = parseInt(this.$route.params.appId as string);
        await api.removeApp(appId);

        this.$router.push({ name: 'apps-installed' });
    }
}


import { Inject, Prop, Provide, VModel, Watch } from 'vue-property-decorator';
import Spinner from '@/components/common/Spinner.vue';
import { Options, Vue } from 'vue-class-component';
import { ListViewModel } from '@/components/automation/flows/designer/ListViewModel';
import { reactive } from 'vue';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import { Field, FieldValue } from '@/components/automation/flows/designer/ConfigComponents/ObjectConfig.vue';
import { ConfigComponent } from '@/components/automation/flows/designer/ConfigComponents/config-components';
import moment from 'moment';
import { Created } from '@/decorators/LifeCycle';
import { BaseObject } from '@/components/automation/flows/definitions/FlowDefinitions';

@ConfigComponent('day-interval')
@Options({
    components: {
        Spinner,
    },
    emits: ['dependency-change', 'input', 'helptext'],
})
export default class ConfigDayInterval extends Vue {
    @Inject() vm!: DesignerViewModel;

    @Prop()
    protected inRow: boolean = false;

    @Prop()
    protected field!: Field;

    @Prop()
    protected modelValue!: FieldValue;

    @Prop()
    protected object!: BaseObject;

    protected start = moment().format('YYYY-MM-DD');
    protected number = 1;
    protected type = 'month';
    protected weekdays = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    };

    protected day = '';
    protected time = 'month';

    protected dayOptions: { label: string; value: string }[] = [];

    protected dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    protected nthNames = ['zeroth', 'first', 'second', 'third', 'fourth'];

    @Watch('start')
    @Watch('time')
    @Watch('number')
    @Watch('type')
    @Watch('weekdays', { deep: true })
    @Watch('day')
    updateModelValue(): void {
        this.modelValue.value = JSON.stringify({
            start: this.start,
            time: this.time,
            number: this.number,
            type: this.type,
            weekdays: this.weekdays,
            day: this.day,
        });
    }

    @Created
    @Watch('modelValue')
    interpretModelValue(): void {
        if (this.modelValue.value) {
            const value = JSON.parse(this.modelValue.value);
            this.start = value.start;
            this.time = value.time;
            this.number = value.number;
            this.type = value.type;
            this.weekdays = value.weekdays;
            this.day = value.day;
        }
    }

    @Created
    @Watch('type')
    @Watch('start')
    changeType(): void {
        console.log('changeType', this.type, this.start);

        this.dayOptions.splice(0);

        const start = moment(this.start);
        const nthName = this.nthNames[Math.floor((start.date() - 1) / 7) + 1];
        switch (this.type) {
            case 'month': {
                this.dayOptions.push({ label: 'On day ' + start.date(), value: 'fixed_day' });

                if (nthName) {
                    this.dayOptions.push({
                        label: 'On ' + nthName + ' ' + this.dayNames[start.day()],
                        value: nthName,
                    });
                }

                console.log(moment(this.start).add(7, 'days').format('YYYY-MM-DD'), moment(this.start).add(7, 'days').isSame(start, 'month'));

                if (!moment(this.start).add(7, 'days').isSame(start, 'month')) {
                    this.dayOptions.push({
                        label: 'On the last ' + this.dayNames[start.day()] + ' of the month',
                        value: 'last',
                    });
                }
                break;
            }
            case 'year':
                this.dayOptions.push({ label: 'On ' + start.format('MMMM Do'), value: 'fixed_date' });
                if (nthName) {
                    this.dayOptions.push({
                        label: 'On the ' + nthName + ' ' + this.dayNames[start.day()] + ' of ' + start.format('MMMM'),
                        value: nthName + '_daymonth',
                    });
                }

                if (!moment(this.start).add(7, 'days').isSame(start, 'month')) {
                    this.dayOptions.push({ label: 'On the last ' + this.dayNames[start.day()] + ' of ' + start.format('MMMM'), value: 'last_daymonth' });
                }
                break;
            case 'day':
                this.weekdays.monday = true;
                this.weekdays.tuesday = true;
                this.weekdays.wednesday = true;
                this.weekdays.thursday = true;
                this.weekdays.friday = true;
                this.weekdays.saturday = true;
                this.weekdays.sunday = true;

            /* falls through */
            default:
                this.dayOptions = [];
                break;
        }
    }

    @Watch('weekdays', { deep: true })
    changeWeekdays(): void {
        console.log('changeWeekdays', this.weekdays);

        // If type is day and one weekdays is not selected, change type to week
        if (
            this.type === 'day' &&
            (!this.weekdays.monday ||
                !this.weekdays.tuesday ||
                !this.weekdays.wednesday ||
                !this.weekdays.thursday ||
                !this.weekdays.friday ||
                !this.weekdays.saturday ||
                !this.weekdays.sunday)
        ) {
            this.type = 'week';
        }
    }
}

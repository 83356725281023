
import { Options, Vue } from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import {
    Connection,
    FlowConnectionType,
    FlowObjectData,
} from '@/components/automation/flows/definitions/FlowDefinitions';

@Options({
    components: {},
})
export default class AddObjectWindow extends Vue {
    @Inject() vm!: DesignerViewModel;

    @Prop()
    type!: string;

    @Prop()
    params!: any;

    connectionTypes: FlowConnectionType[] = [];

    connectionType: null | FlowConnectionType = null;

    addObject() {
        console.log('add object');
    }

    get filteredObjectTypes() {
        const result: { [category: string]: FlowObjectData[] } = {};

        for (const category in this.vm.groupedObjectTypes) {
            let objectTypes = this.vm.groupedObjectTypes[category];
            objectTypes = objectTypes.filter((objectType: { category: string }) => {
                return objectType.category !== 'start';
            });

            if (objectTypes.length > 0) {
                result[category] = objectTypes;
            }
        }

        return result;
    }

    async created() {
        if (this.params && this.params.previousObject) {
            this.connectionTypes = await this.params.previousObject.remote().getAvailableConnectionTypes();
            this.connectionType =
                this.connectionTypes.filter((t: { default: any }) => t.default)[0] ?? this.connectionTypes[0];
        }
    }
}

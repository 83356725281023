import { Flow } from '@/components/automation/flows/designer/Flow';
import asyncTimeout from '@/shared/async-timer';
import api from '@/shared/api';

export default class FlowStore {
    async fetch() {
        // TODO:
        // console.log('waiting...');
        // await asyncTimeout(1000);
        // console.log('fetched');
        // return [];
        // return HTTP.get(['rest', 'flow', 'get'])
        //     .then(response => response.data.data.map(flow => new Flow(flow)));
        return await api.getFlows();
    }

    async add(flow: Flow) {
        const response = await api.addFlow(flow.name);
        // TODO
        // return HTTP.post(['rest', 'flow', 'post'], {name: flow.name})
        //     .then(response => response.data.data.id);
        return response.id;
    }
}

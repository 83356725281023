
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BaseObject } from '@/components/automation/flows/definitions/FlowDefinitions';
import TreeTable from '@/components/common/treetable/Root.vue';
import { Options, Vue } from 'vue-class-component';

@Options({
    components: { TreeTable },
})
export default class ValueSelector extends Vue {
    @Prop() protected modelValue!: any;
    @Prop() protected object!: BaseObject;
    @Prop() protected options!: any[];
    @Prop() protected level!: number;

    protected expanded: boolean[] = [];

    selectItem(option: any) {
        this.$emit('update:modelValue', option);
    }
}


import { Inject, Prop, Provide, VModel, Watch } from 'vue-property-decorator';
import Spinner from '@/components/common/Spinner.vue';
import { Options, Vue } from 'vue-class-component';
import { ListViewModel } from '@/components/automation/flows/designer/ListViewModel';
import { reactive } from 'vue';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import { Field, FieldValue } from '@/components/automation/flows/designer/ConfigComponents/ObjectConfig.vue';
import { ConfigComponent } from '@/components/automation/flows/designer/ConfigComponents/config-components';
import VariableSelector from '@/components/automation/flows/designer/VariableSelector.vue';
import { BaseObject } from '@/components/automation/flows/definitions/FlowDefinitions';

@ConfigComponent('variable-selector')
@Options({
    components: {
        VariableSelector,
        Spinner,
    },
})
export default class ConfigVariableSelector extends Vue {
    @Inject() vm!: DesignerViewModel;

    @Prop()
    protected inRow: boolean = false;

    @Prop()
    public object!: BaseObject;

    @Prop()
    protected field!: Field;

    @Prop()
    protected modelValue!: FieldValue;

    @Watch('modelValue', { deep: true })
    onModelValueChanged() {
        this.$emit('input', this.modelValue);
    }
}

import { BaseObject, Connection } from '@/components/automation/flows/definitions/FlowDefinitions';
import { AnyObject } from '@/shared/any-object';
import api from '@/shared/api';

class Flow {
    public id = '';
    public name = '';
    public group = '';
    public active = false;
    public readonly = false;
    public connections: Connection[] = [];
    public objects: BaseObject[] = [];
    public dirty = false;

    constructor({ id, name, group, active }: { id?: string; name?: string; group?: string; active?: boolean } = {}) {
        if (id) this.id = id;
        if (name) this.name = name;
        if (group) this.group = group;
        if (active) this.active = active;
    }

    getJson(): AnyObject {
        return {
            id: this.id,
            name: this.name,
            group: this.group,
            active: this.active,
            connections: this.connections.map((connection) => connection.getJson()),
            objects: this.objects.map((object) => object.getJson()),
        };
    }

    async activate() {
        const flow = await api.post('flow/activate', this.getJson());
        this.active = true;
        return flow;
    }

    async deactivate() {
        const flow = await api.post('flow/deactivate', this.getJson());
        this.active = false;
        return flow;
    }

    async save() {
        const response = await api.post('flow/save', this.getJson());
        this.dirty = false;
        return response;
    }

    async load() {
        const flowData = await api.get('flow/get/' + this.id);
        this.name = flowData.name;
        this.active = flowData.active;
        this.readonly = flowData.readonly;
        this.objects = flowData.objects.map((data: any) => {
            const object = new BaseObject();
            object.id = data.id;
            object.type = data.type;
            object.x = data.x;
            object.y = data.y;

            object.description = data.description;
            object.title = data.title;
            object.icon = data.icon;
            object.category = data.category;
            object.subCategory = data.subCategory;
            object.config = data.config;
            object.flow = this;

            return object;
        });

        const objectsById: any = {};
        for (const i in this.objects) {
            objectsById[this.objects[i].id] = this.objects[i];
        }

        this.connections = flowData.connections.map((data: any) => {
            const connection = new Connection();
            connection.id = data.id;
            connection.type = data.type;
            connection.waypoints = data.waypoints;
            connection.from = objectsById[data.from];
            connection.to = objectsById[data.to];

            return connection;
        });

        this.dirty = false;
    }
}

export { Flow };

import ViewModel from '@/components/automation/flows/designer/ViewModel';
import FlowStore from '@/components/automation/flows/designer/FlowStore';
import { Flow } from '@/components/automation/flows/designer/Flow';
import { Provide } from 'vue-property-decorator';
import api from '@/shared/api';
import { FlowObjectData } from '@/components/automation/flows/definitions/FlowDefinitions';
import {Content} from "@/components/content/Content";

export default class EditorViewModel implements ViewModel {
    public loading = true;
    public ready = false;
    public saving = false;
    public dirty = true;

    public content: Content = new Content();


    async init() {
        // do nothing
    }

}

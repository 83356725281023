import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52b370f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "editor" }
const _hoisted_2 = {
  key: 0,
  class: "editor-wrapper",
  ref: "wrapper"
}
const _hoisted_3 = {
  key: 1,
  class: "save-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_toolbar = _resolveComponent("content-toolbar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_content_toolbar),
    (_ctx.vm.ready)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vm.content.data.html) = $event)),
            class: "form-control",
            rows: "10",
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.vm.content.dirty = true))
          }, null, 544), [
            [_vModelText, _ctx.vm.content.data.html]
          ])
        ], 512))
      : _createCommentVNode("", true),
    (_ctx.vm.saving)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ]))
}

import { Inject, Prop, Provide, VModel } from 'vue-property-decorator';
import Spinner from '@/components/common/Spinner.vue';
import { Options, Vue } from 'vue-class-component';
import { ListViewModel } from '@/components/automation/flows/designer/ListViewModel';
import { reactive } from 'vue';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';
import { Field, FieldValue } from '@/components/automation/flows/designer/ConfigComponents/ObjectConfig.vue';
import ObjectConfigComponent from '@/components/automation/flows/designer/ConfigComponents/ObjectConfigComponent.vue';
import { ConfigComponent } from '@/components/automation/flows/designer/ConfigComponents/config-components';
import { BaseObject } from '@/components/automation/flows/definitions/FlowDefinitions';
import { cloneDeep } from '@/shared/clonedeep';

@ConfigComponent('group')
@Options({
    components: {
        Spinner,
        'object-config-component': ObjectConfigComponent,
    },
    emits: ['dependency-change'],
})
export default class ConfigGroup extends Vue {
    @Inject() vm!: DesignerViewModel;

    @Prop()
    public object!: BaseObject;

    @Prop()
    protected field!: Field;

    @Prop()
    protected modelValue!: FieldValue;

    protected ready = false;

    created() {
        if (!this.modelValue.childValues) {
            this.modelValue.childValues = {};
        }

        for (let i in this.field.children) {
            if (!this.modelValue.childValues[i]) {
                this.modelValue.childValues[i] = {
                    type: 'value',
                    value: '',
                };
            }
        }

        console.log('field', this.field);

        this.ready = true;
    }

    get layoutClasses() {
        return {
            'd-flex': true,
            'flex-row': this.field.params?.layout === 'horizontal',
            'flex-column': this.field.params?.layout === 'vertical',
        };
    }

    addTemplate(templateName: string) {
        if (
            this.field.children &&
            this.field.fieldOrder &&
            this.field.params?.template &&
            this.field.params?.template[templateName] &&
            this.modelValue.childValues
        ) {
            let counter = 0;
            while (this.field.children['child' + counter]) {
                counter++;
            }
            const fieldName = 'child' + counter;
            this.modelValue.childValues[fieldName] = {
                type: 'value',
                value: '',
            };
            this.field.children[fieldName] = cloneDeep(this.field.params.template[templateName]);
            const params = this.field.children[fieldName].params;
            if (params && params.recursive) {
                params.template = this.field.params.template;
            }
            this.field.fieldOrder.push(fieldName);
        }
    }

    removeRow(fieldIndex: number) {
        if (this.field.children && this.field.fieldOrder && this.field.params?.template) {
            const fieldName = this.field.fieldOrder[fieldIndex];
            delete this.field.children[fieldName];
            if (this.modelValue.childValues) {
                delete this.modelValue.childValues[fieldName];
            }
            this.field.fieldOrder.splice(fieldIndex, 1);
        }
    }

    isRemovable(fieldName: string) {
        if (this.field.params?.template) {
            return fieldName.match(/child\d+/);
        }
        return false;
    }
}

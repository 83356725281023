import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e394e160"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 0,
  for: "fieldX"
}
const _hoisted_3 = ["rows"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.inRow)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.field.label), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("textarea", {
      type: "text",
      class: _normalizeClass(["form-control", _ctx.cssClass]),
      id: "fieldX",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.value) = $event)),
      "aria-describedby": "helpX",
      rows: _ctx.field.params.rows ?? 8
    }, null, 10, _hoisted_3), [
      [_vModelText, _ctx.modelValue.value]
    ])
  ]))
}
import { BaseObject, Connection } from '@/components/automation/flows/definitions/FlowDefinitions';
import { AnyObject } from '@/shared/any-object';
import api from '@/shared/api';

class Content {
    public id = '';
    public name = '';
    public data: any = {};
    public group = '';
    public active = false;
    public readonly = false;
    public type: string = '';
    public dirty = false;

    constructor({ id, type, name, data, group, active }: { id?: string; type?: string; data?: any; name?: string; group?: string; active?: boolean } = {}) {
        if (id) this.id = id;
        if (name) this.name = name;
        if (group) this.group = group;
        if (active) this.active = active;
        if (type) this.type = type;
    }

    getJson(): AnyObject {
        return {
            id: this.id,
            type: this.type,
            name: this.name,
            data: this.data,
            group: this.group,
            active: this.active,
        };
    }

    async save() {
        const response = await api.post('content/save', this.getJson());
        this.dirty = false;
        return response;
    }

    async load() {
        const contentData = await api.get('content/get/' + this.id);
        this.name = contentData.name;
        this.data = contentData.data || {};
        this.type = contentData.type;
        this.active = contentData.active;
        this.readonly = contentData.readonly;
        this.dirty = false;
    }
}

export { Content };

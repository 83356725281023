import ViewModel from '@/components/automation/flows/designer/ViewModel';
import FlowStore from '@/components/automation/flows/designer/FlowStore';
import { Flow } from '@/components/automation/flows/designer/Flow';
import { Provide } from 'vue-property-decorator';
import api from '@/shared/api';
import { FlowObjectData } from '@/components/automation/flows/definitions/FlowDefinitions';

export default class DesignerViewModel implements ViewModel {
    public loading = true;
    public ready = false;

    public readonly: boolean = false;
    public advanced: boolean = false;
    public selected: any = null;

    public flow: Flow = new Flow();

    public objectTypes: FlowObjectData[] = [];

    async init() {
        this.objectTypes = await api.get('flow/object-types');
    }

    get groupedObjectTypes() {
        const result: any = {};
        this.objectTypes.forEach((objectType) => {
            if (!result[objectType.group]) {
                result[objectType.group] = [];
            }
            result[objectType.group].push(objectType);
        });
        return result;
    }

    getIconUrl(icon: string) {
        const images = require.context('../../../../assets/flow-objects/', false, /\.svg$/);
        try {
            return images('./' + icon + '.svg');
        } catch (e) {
            return images('./unknown.svg');
        }
    }

    getWarningIcon() {
        const images = require.context('../../../../assets/', false, /\.svg$/);
        return images('./warning.svg');
    }
}


import { Provide, Watch } from 'vue-property-decorator';
import { Created, Destroyed } from '@/decorators/LifeCycle';
import { Options, Vue } from 'vue-class-component';
import EditorViewModel from "@/components/content/EditorViewModel";
import ContentToolbar from "@/components/content/ContentToolbar.vue";

@Options({
    components: {
        ContentToolbar,
    },
})
export default class Editor extends Vue {
    @Provide() vm = new EditorViewModel();

    @Created
    async init(): Promise<void> {
        this.vm.init();
        this.vm.ready = true;
        // this.flow = new Flow();
        this.vm.content.id = this.$route.params.contentId as string;

        await this.vm.content.load();

        window.addEventListener('keydown', this.onKeyDown);
    }

    @Destroyed
    uninitialize(): void {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown(e: KeyboardEvent): void {
        if (e.target !== document.body) {
            return;
        }
        console.log('keydown', e.target);

        // if (e.key === 'Escape') {
        // } else if (e.key === 'Delete') {
        // }
    }

    @Watch('$route', { deep: true })
    async loadContent(): Promise<void> {
        if (this.$route.params.contentId) {
            this.vm.content.id = this.$route.params.contentId as string;
            await this.vm.content.load();
        }
    }
}

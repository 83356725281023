
import { Options, Vue } from 'vue-class-component';
import AppTile from './AppTile.vue';
import api from '@/shared/api';
import getAppDefinitions, { AppDefinitions } from '@/shared/apps';

@Options({
    components: {
        AppTile,
    },
})
export default class AppOverview extends Vue {
    protected installedApps = [];
    protected availableApps: AppDefinitions = {};

    protected ready = false;

    async created() {
        this.availableApps = await getAppDefinitions();
        const apps = await api.getInstalledApps();
        console.log('installedApps', apps);
        this.installedApps = apps;

        this.ready = true;
    }
}

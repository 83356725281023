
import { Options, Vue } from 'vue-class-component';
import api from '@/shared/api';
import session from '@/shared/session';

@Options({
    components: {},
})
export default class ResetPassword extends Vue {
    protected error = '';
    protected requested = false;
    protected password = '';
    protected password_verify = '';

    async resetPassword() {
        this.error = '';
        try {
            const data = await api.authReset(this.$route.params.token as string, this.password);
            console.log('authRecover result', data);
            this.requested = true;
        } catch (error) {
            console.error(error);
            this.error = String(error).toString();
        }
    }
}

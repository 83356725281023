
import { Options, Vue } from 'vue-class-component';
import AppTile from './AppTile.vue';
import api from '@/shared/api';
import { LocationQueryValue } from 'vue-router';
import getAppDefinitions, { AppDefinitions } from '@/shared/apps';
import {ExternalSystem} from "@/components/automation/externalsystems/ExternalSystems.vue";

@Options({
    components: {
        AppTile,
    },
})
export default class AppSprinklrConnectSystem extends Vue {
    protected app: null | any = null;
    protected apps: AppDefinitions = {};

    protected externalSystems: ExternalSystem[] = [];
    protected selectedAccount: null|ExternalSystem = null;

    async saveApp() {
        if (this.selectedAccount && this.selectedAccount.id) {
            console.log('saveApp');
            const appId = parseInt(this.$route.params.appId as string);

            const result = await api.connectAppToSystem(appId, this.selectedAccount.id);
            await this.$router.push({ name: 'app-settings', params: { appId: result.app_id } });
        }
    }

    async created() {
        this.apps = await getAppDefinitions();

        const appId = parseInt(this.$route.params.appId as string);

        this.app = await api.getAppSettings(appId);
        this.externalSystems = await api.getExternalSystems();
    }

    get sprinklrAccounts() {
        return this.externalSystems.filter((system) => system.type === 'sprinklr');
    }

    protected removeModal = false;
    async removeApp() {
        this.removeModal = true;
    }
    async removeAppConfirm() {
        const appId = parseInt(this.$route.params.appId as string);
        await api.removeApp(appId);

        this.$router.push({ name: 'app-store' });
    }
}

import { reactive } from 'vue';
import api from '@/shared/api';

export type AppDataInstall = {
    route: string;
    params: { [key: string]: any };
};

export type AppDataKey = {
    key: string;
};

export type AppDefinition = {
    title: string;
    icon: string;
    short_description: string;
    description: string;
    install: AppDataInstall;
    redirect_uri: string;
    api_keys: {
        [env: string]: AppDataKey;
    };
};

export type AppDefinitions = { [key: string]: AppDefinition };

let appsDataLoaded = false;
let appsData: AppDefinitions = {
    // "expired-assets": {
    //     title: "Expired Assets",
    //     icon: 'far fa-3x fa-file-chart-pie',
    //     short_description: "Automatically update assets when they are about to expire.",
    //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse\n" +
    //         "            leo sapien, convallis eget tempus nec, volutpat sed est. Morbi\n" +
    //         "            tempus facilisis dui non venenatis. Praesent bibendum lorem est,\n" +
    //         "            quis accumsan dolor accumsan eu. Vivamus vitae lorem quam. Nam\n" +
    //         "            semper neque pretium mauris ultrices luctus. Cras et lorem mauris.\n" +
    //         "            Vivamus feugiat nec nunc quis tincidunt.",
    //     install: {
    //         route: 'app-sprinklr-authorize',
    //         params: {
    //
    //         }
    //     },
    //     client_id: 'qymrpscbt53dj354sbcwf8ay',
    //     redirect_uri: 'https://huur.gewoonsimon.in/sprinklr-callback',
    // },
    // "usergroup-share": {
    //     title: "User Group Share",
    //     icon: 'far fa-3x fa-users',
    //     short_description:
    //         "Automatically share assets uploaded by a distributed user with their teams.",
    //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse\n" +
    //         "            leo sapien, convallis eget tempus nec, volutpat sed est. Morbi\n" +
    //         "            tempus facilisis dui non venenatis. Praesent bibendum lorem est,\n" +
    //         "            quis accumsan dolor accumsan eu. Vivamus vitae lorem quam. Nam\n" +
    //         "            semper neque pretium mauris ultrices luctus. Cras et lorem mauris.\n" +
    //         "            Vivamus feugiat nec nunc quis tincidunt.",
    //     install: {
    //         route: 'app-sprinklr-authorize',
    //         params: {
    //
    //         }
    //     },
    //     client_id: 'wsnbpfk8z6xy4f6q99qxy3ud',
    //     redirect_uri: 'https://huur.gewoonsimon.in/sprinklr-callback',
    // },
    // "image-crop-resize": {
    //     title: "Image Crop & Resize",
    //     icon: 'far fa-3x fa-crop',
    //     short_description:
    //         "Automatically crop & resize image assets when they are uploaded.",
    //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse\n" +
    //         "            leo sapien, convallis eget tempus nec, volutpat sed est. Morbi\n" +
    //         "            tempus facilisis dui non venenatis. Praesent bibendum lorem est,\n" +
    //         "            quis accumsan dolor accumsan eu. Vivamus vitae lorem quam. Nam\n" +
    //         "            semper neque pretium mauris ultrices luctus. Cras et lorem mauris.\n" +
    //         "            Vivamus feugiat nec nunc quis tincidunt.",
    //     install: {
    //         route: 'app-sprinklr-authorize',
    //         params: {
    //
    //         }
    //     },
    //     client_id: 'vdkdjwy8gmqmsqqry6jwg5fa',
    //     redirect_uri: 'https://huur.gewoonsimon.in/sprinklr-callback',
    // },
    // "asset-unzip": {
    //     title: "Asset Unzip",
    //     icon: 'far fa-3x fa-file-archive',
    //     short_description:
    //         "Automatically extract zip assets when they are uploaded and upload the content as new assets.",
    //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse\n" +
    //         "            leo sapien, convallis eget tempus nec, volutpat sed est. Morbi\n" +
    //         "            tempus facilisis dui non venenatis. Praesent bibendum lorem est,\n" +
    //         "            quis accumsan dolor accumsan eu. Vivamus vitae lorem quam. Nam\n" +
    //         "            semper neque pretium mauris ultrices luctus. Cras et lorem mauris.\n" +
    //         "            Vivamus feugiat nec nunc quis tincidunt.",
    //     install: {
    //         route: 'app-sprinklr-authorize',
    //         params: {
    //
    //         }
    //     },
    //     client_id: '123',
    //     redirect_uri: 'https://huur.gewoonsimon.in/sprinklr-callback',
    // }
};

export default async function getAppDefinitions(): Promise<AppDefinitions> {
    if (!appsDataLoaded) {
        appsData = await api.getAppDefinitions();
        appsDataLoaded = true;
    }
    return appsData;
}

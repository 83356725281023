//import 'bootstrap/dist/css/bootstrap.css';
import './css/main.scss';
import 'bootstrap';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
// @ts-ignore
import vClickOutside from 'click-outside-vue3';

const app = createApp(App).use(router).use(vClickOutside).mount('#app');

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-271ce1f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-3" }
const _hoisted_2 = {
  key: 0,
  class: "wrap"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_object_config_component = _resolveComponent("object-config-component")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.ready)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldOrder, (fieldName, fieldIdx) => {
            return (_openBlock(), _createBlock(_component_object_config_component, {
              key: fieldIdx,
              fieldName: fieldName,
              field: _ctx.children[fieldName],
              value: _ctx.fieldValues[fieldName],
              object: _ctx.object,
              onDependencyChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dependency-change', $event))),
              onCustomAction: _ctx.runCustomAction,
              onHelptext: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('helptext', $event)))
            }, null, 8, ["fieldName", "field", "value", "object", "onCustomAction"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Spinner)
        ]))
  ]))
}
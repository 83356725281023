import { Flow } from '@/components/automation/flows/designer/Flow';
import api from '@/shared/api';
import { AnyObject } from '@/shared/any-object';

export type FlowConnectionType = {
    key: string;
    label: string;
    default: boolean;
};

export declare type Class = {
    new (...args: any[]): any;
    [data: string]: any[] | any;
};

export class Connection {
    public id!: number;
    public type!: FlowConnectionType;
    public from!: BaseObject;
    public to!: BaseObject;
    public waypoints: Waypoint[] = [];
    //
    // toJSON(): any {
    //     return {
    //         id: this.id,
    //         type: this.type,
    //         from: this.from.id,
    //         to: this.to.id,
    //         waypoints: this.waypoints
    //     };
    // }

    getJson() {
        return {
            id: this.id,
            type: this.type,
            from: this.from.id,
            to: this.to.id,
            waypoints: this.waypoints.map((waypoint) => ({ x: waypoint.x, y: waypoint.y })),
        };
    }
}

export class ObjectDefinition {
    public readonly type!: string;
    public readonly icon!: string;
    public readonly title!: string;
    public readonly category!: string;
    public readonly subCategory?: string;
    public readonly available?: boolean;
}

export type FlowObjectData = {
    type: string;
    title: string;
    category: string;
    subCategory: string;
    icon: string;
    group: string;
};

export class BaseObject {
    public id: string = (-Math.round(Math.random() * 10000000)).toString();
    public outgoing_connections!: Connection[];
    public incoming_connections!: Connection[];
    public flow!: Flow;
    public config: AnyObject = {};
    public title!: string;
    public description!: string;
    public category!: string;
    public subCategory!: string;
    public icon!: string;
    public type!: string;
    public status: boolean | null = null;
    public statusInfo: string = '';
    public x!: number;
    public y!: number;

    protected _remote!: { [type: string]: (...params: any) => Promise<any> };

    async updateStatus() {
        const statusInfo = await this.remote().getStatusInfo();
        this.status = statusInfo.status;
        this.statusInfo = statusInfo.info;
    }

    remote(): { [type: string]: (...params: any) => Promise<any> } {
        if (!this._remote) {
            this._remote = new Proxy(
                {},
                {
                    get: (t, call, r) => {
                        return (...params: any): Promise<any> => {
                            return new Promise((resolve, reject) => {
                                // TODO:
                                console.log('flow/remote-object-call');
                                const data = api
                                    .post('flow/remote-object-call?' + call.toString(), {
                                        flowData: this.flow.getJson(),
                                        objectId: this.id,
                                        call: call,
                                        params: params,
                                    })
                                    .then(resolve);
                            });
                        };
                    },
                },
            );
        }
        return this._remote;
    }

    getJson() {
        return {
            id: this.id,
            config: this.config,
            title: this.title,
            description: this.description,
            category: this.category,
            icon: this.icon,
            type: this.type,
            status: this.status,
            x: this.x,
            y: this.y,
        };
    }
}

export class Waypoint {
    public x!: number;
    public y!: number;
}


import { Options, Vue } from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import Modal from '@/components/common/Modal.vue';
import EditorViewModel from "@/components/content/EditorViewModel";

@Options({
    props: {},
    components: {
        Modal,
    },
})
export default class ContentToolbar extends Vue {
    @Inject() vm!: EditorViewModel;

    save() {
        this.vm.content.save();
    }
}


import { Options, Vue } from 'vue-class-component';
import session from '@/shared/session';
import { Watch } from 'vue-property-decorator';
import BusinessUnitSwitcher from '@/components/common/business-unit-switcher/BusinessUnitSwitcher.vue';

type BusinessUnitData = {
    id: string;
    name: string;
    parent?: BusinessUnitData;
    children: BusinessUnitData[];
};

@Options({
    components: {
        BusinessUnitSwitcher,
    },
    props: {},
})
export default class TopBar extends Vue {
    selectBusinessUnit = false;
    businessUnits: BusinessUnitData[] = [];
    currentBusinessUnit: string | null = null;

    get isAdmin() {
        return session.hasRole('admin');
    }

    mounted() {
        const buById: { [key: string]: BusinessUnitData } = {};
        for (let bu of session.getBusinessUnits()) {
            buById[bu.id] = Object.assign({ children: [] }, bu);
        }

        for (let bu of Object.values(buById)) {
            if (!bu.parent) {
                this.businessUnits.push(bu);
            }
        }

        this.currentBusinessUnit = session.getCurrentBusinessUnit();
    }

    updateCurrentBusinessUnit(bu: string | null) {
        this.currentBusinessUnit = bu;
        this.selectBusinessUnit = false;
        session.setCurrentBusinessUnit(bu);
    }

    get currentBusinessUnitName() {
        if (this.currentBusinessUnit) {
            for (let bu of session.getBusinessUnits()) {
                if (bu.id === this.currentBusinessUnit) {
                    return bu.name;
                }
            }
        }
        return '';
    }
}


import { Options, Vue } from 'vue-class-component';
import AppTile from './AppTile.vue';
import getAppDefinitions, { AppDefinitions } from '@/shared/apps';

@Options({
    components: {
        AppTile,
    },
})
export default class AppInstall extends Vue {
    protected apps: AppDefinitions = {};

    get appName() {
        let appname = this.$route.params.appname;
        if (appname instanceof Array) {
            appname = appname[0];
        }
        return appname;
    }
    get app() {
        return this.apps[this.appName];
    }

    async created() {
        this.apps = await getAppDefinitions();
    }
}

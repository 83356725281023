import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Main from '../layouts/Main.vue';
import Center from '../layouts/Center.vue';
import AppOverview from '../components/apps/AppOverview.vue';
import AppDetails from '../components/apps/AppDetails.vue';
import Login from '../components/login/Login.vue';
import RecoverPassword from '../components/login/RecoverPassword.vue';
import AppInstall from '@/components/apps/AppInstall.vue';
import AppSprinklrAuthorize from '@/components/apps/AppSprinklrAuthorize.vue';
import AppSettings from '@/components/apps/AppSettings.vue';
import session from '@/shared/session';
import AppStore from '@/components/apps/AppStore.vue';
import List from '@/components/automation/flows/designer/List.vue';
import Add from '@/components/automation/flows/designer/Add.vue';
import Designer from '@/components/automation/flows/designer/Designer.vue';
import ResetPassword from '@/components/login/ResetPassword.vue';
import SprinklrApiDebugger from '@/components/SprinklrApiDebugger.vue';
import ManageBusinessUnits from '@/components/admin/ManageBusinessUnits.vue';
import ManageUsers from '@/components/admin/ManageUsers.vue';
import SprinklrApiWebhooks from '@/components/SprinklrApiWebhooks.vue';
import AddUser from '@/components/admin/AddUser.vue';
import AddBusinessUnits from '@/components/admin/AddBusinessUnits.vue';
import ExternalSystems from '@/components/automation/externalsystems/ExternalSystems.vue';
import ExternalSystemsAddSprinklr from '@/components/automation/externalsystems/ExternalSystemsAddSprinklr.vue';
import AssignRole from '@/components/admin/AssignRole.vue';
import ObjectLogsWindow from '@/components/automation/flows/designer/ObjectLogsWindow.vue';
import ContextLogsWindow from '@/components/automation/flows/designer/ContextLogsWindow.vue';
import AppSprinklrConnectSystem from '@/components/apps/AppSprinklrConnectSystem.vue';
import ExternalSystemsAddMicrosoftAzure from '@/components/automation/externalsystems/ExternalSystemsAddMicrosoftAzure.vue';
import ContentList from "@/components/content/ContentList.vue";
import AddContent from "@/components/content/AddContent.vue";
import Editor from "@/components/content/Editor.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'root',
        redirect: { name: 'apps-store' },
    },
    {
        path: '/apps',
        redirect: { name: 'apps-store' },
        children: [
            {
                path: 'sprinklr-authorize',
                name: 'app-sprinklr-authorize-callback',
                component: AppSprinklrAuthorize,
                meta: { layout: Main, authenticated: true },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
            },
            {
                path: 'store',
                name: 'apps-store',
                redirect: { name: 'apps-store-overview' },
                children: [
                    {
                        path: '',
                        name: 'apps-store-overview',
                        component: AppStore,
                        meta: { layout: Main, authenticated: true },
                    },
                    {
                        path: ':appname',
                        name: 'app-details',
                        component: AppDetails,
                        meta: { layout: Main, authenticated: true },
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
                    },
                    {
                        path: ':appname/install',
                        name: 'app-install',
                        component: AppInstall,
                        meta: { layout: Main, authenticated: true },
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
                    },
                ],
            },
            {
                path: 'installed',
                name: 'apps-installed',
                redirect: { name: 'apps-installed-overview' },
                children: [
                    {
                        path: '',
                        name: 'apps-installed-overview',
                        component: AppOverview,
                        meta: { layout: Main, authenticated: true },
                    },
                    {
                        path: ':appId/settings',
                        name: 'app-settings',
                        component: AppSettings,
                        meta: { layout: Main, authenticated: true },
                    },
                    {
                        path: ':appId/sprinklr-authorize',
                        name: 'app-sprinklr-authorize',
                        component: AppSprinklrAuthorize,
                        meta: { layout: Main, authenticated: true },
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
                    },
                    {
                        path: ':appId/app-sprinklr-connectsystem',
                        name: 'app-sprinklr-connectsystem',
                        component: AppSprinklrConnectSystem,
                        meta: { layout: Main, authenticated: true },
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
                    },
                ],
            },
        ],
    },
    {
        path: '/automation',
        redirect: { name: 'apps-store' },
        children: [
            {
                path: 'modules',
                name: 'automation-modules',
                redirect: { name: 'apps-store' },
                meta: { layout: Main, authenticated: true },
            },
            {
                path: 'flows',
                name: 'automation-flows',
                redirect: { name: 'automation-flows-list' },
                children: [
                    {
                        path: '',
                        name: 'automation-flows-list',
                        component: List,
                        meta: { layout: Main, authenticated: true },
                        children: [
                            {
                                path: 'add',
                                name: 'automation-flows-add',
                                meta: { layout: Main, authenticated: true },
                                component: Add,
                            },
                        ],
                    },
                    {
                        path: 'edit/:flowId',
                        name: 'automation-flows-edit',
                        meta: { layout: Main, authenticated: true },
                        component: Designer,
                        children: [
                            {
                                path: 'object-log/:objectId',
                                name: 'automation-flows-edit-object-log',
                                meta: { layout: Main, authenticated: true },
                                component: ObjectLogsWindow,
                            },
                            {
                                path: 'context-log/:contextId',
                                name: 'automation-flows-edit-context-log',
                                meta: { layout: Main, authenticated: true },
                                component: ContextLogsWindow,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '/content',
        name: 'content',
        redirect: { name: 'content-list' },
        children: [
            {
                path: '',
                name: 'content-list',
                component: ContentList,
                meta: { layout: Main, authenticated: true },
                children: [
                    {
                        path: 'add',
                        name: 'content-add',
                        meta: { layout: Main, authenticated: true },
                        component: AddContent,
                    },
                ],
            },
            {
                path: 'edit/:contentId',
                name: 'content-edit',
                meta: { layout: Main, authenticated: true },
                component: Editor,
            },
        ],
    },
    {
        path: '/config',
        name: 'config',
        redirect: { name: 'apps-store' },
    },
    {
        path: '/external-systems',
        name: 'external-systems',
        meta: { layout: Main, authenticated: true },
        component: ExternalSystems,
        children: [
            {
                path: 'authorize-sprinklr/:newOrId',
                name: 'external-systems-authorize-sprinklr',
                meta: { layout: Main, authenticated: true },
                component: ExternalSystemsAddSprinklr,
            },
            {
                path: 'authorize-microsoftazure/:newOrId',
                name: 'external-systems-authorize-microsoftazure',
                meta: { layout: Main, authenticated: true },
                component: ExternalSystemsAddMicrosoftAzure,
            },
        ],
    },
    {
        path: '/manage/Businessunits',
        redirect: { name: 'manage-businessunits' },
        children: [
            {
                path: '',
                name: 'manage-businessunits',
                meta: { layout: Main, authenticated: true },
                component: ManageBusinessUnits,
            },
            {
                path: '/add',
                name: 'add-businessunits',
                meta: { layout: Main, authenticated: true },
                component: AddBusinessUnits,
            },
        ],
    },
    {
        path: '/manage/users',
        redirect: { name: 'manage-users' },
        children: [
            {
                path: '',
                name: 'manage-users',
                meta: { layout: Main, authenticated: true },
                component: ManageUsers,
            },
            {
                path: '/add',
                name: 'add-users',
                meta: { layout: Main, authenticated: true },
                component: AddUser,
            },
            {
                path: '/assign-role',
                name: 'assign-role',
                meta: { layout: Main, authenticated: true },
                component: AssignRole,
                props: (route) => ({ userId: route.query.userId }),
            },
        ],
    },

    {
        path: '/sprinklr-api',
        name: 'sprinklr-api',
        meta: { layout: Main, authenticated: true },
        component: SprinklrApiDebugger,
    },
    {
        path: '/sprinklr-webhooks',
        name: 'sprinklr-webhooks',
        meta: { layout: Main, authenticated: true },
        component: SprinklrApiWebhooks,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { layout: Center },
    },
    {
        path: '/logout',
        name: 'logout',
        redirect: (to) => {
            session.logout();
            return { name: 'login' };
        },
    },
    {
        path: '/recover-password',
        name: 'recover-password',
        component: RecoverPassword,
        meta: { layout: Center },
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: ResetPassword,
        meta: { layout: Center },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    let authenticated = false;
    for (const i in to.matched) {
        if (to.matched[i] && to.matched[i].meta && to.matched[i].meta.authenticated) {
            authenticated = true;
            break;
        }
    }
    if (authenticated) {
        console.log('authenticated route');
        session.checkSession().then(
            (loggedIn) => {
                if (loggedIn) {
                    next();
                } else {
                    next('/login');
                }
            },
            () => {
                next('/login');
            },
        );
    } else {
        next();
    }
});

export default router;

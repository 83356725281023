
import { Provide, Prop, Inject, InjectReactive, Watch } from 'vue-property-decorator';
import {
    BaseObject,
    Connection,
    ObjectDefinition,
    Waypoint,
} from '@/components/automation/flows/definitions/FlowDefinitions';
import { BeforeCreate, Created, Mounted } from '@/decorators/LifeCycle';
import { Options, Vue } from 'vue-class-component';
import ObjectConfig from '@/components/automation/flows/designer/ConfigComponents/ObjectConfig.vue';
import moment from 'moment';
import DesignerViewModel from '@/components/automation/flows/designer/DesignerViewModel';

@Options({
    components: { ObjectConfig },
})
export default class ObjectLogsWindow extends Vue {
    protected object?: BaseObject | Record<string, never> = {};
    @Inject() vm!: DesignerViewModel;

    @InjectReactive()
    protected now!: number;

    get nowMoment() {
        return moment(this.now);
    }

    protected logs = [];

    formatDate(date: string): string {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }

    formatDateDiff(date1: string, date2: string): string {
        // if the difference in seconds is lower than 120, show the seconds
        if (moment(date2).diff(moment(date1), 'seconds') < 120) {
            return moment(date2).diff(moment(date1), 'seconds') + ' seconds';
        }
        return moment(date1).from(moment(date2), true);
    }

    formatDateAgo(date: string): string {
        if (this.nowMoment) {
            return moment(date).fromNow();
        }
        return '';
    }

    @Created
    @Watch('vm', { deep: true })
    async getLogs(): Promise<void> {
        // loop through objects in vm.flow and find the object with the same id
        this.object = this.vm.flow.objects.find((obj) => obj.id === (this.$route.params.objectId as string));
        if (this.object && this.object.remote) {
            this.logs = await this.object.remote().getLogs();
        }
    }
}

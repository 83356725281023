import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "form-group d-flex flex-row" }
const _hoisted_2 = ["name", "value", "id"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.field.params.options, (option, optionIdx) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "form-check me-3",
        key: optionIdx
      }, [
        _withDirectives(_createElementVNode("input", {
          class: "form-check-input",
          type: "radio",
          name: _ctx.radioName(),
          value: option.value ?? option,
          id: _ctx.radioName() + '-' + (option.value ?? option),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.value) = $event))
        }, null, 8, _hoisted_2), [
          [_vModelRadio, _ctx.modelValue.value]
        ]),
        _createElementVNode("label", {
          class: "form-check-label",
          for: _ctx.radioName() + '-' + (option.value ?? option)
        }, _toDisplayString(option.label ?? option), 9, _hoisted_3)
      ]))
    }), 128))
  ]))
}

import { Prop, Provide } from 'vue-property-decorator';
import Spinner from '@/components/common/Spinner.vue';
import { Options, Vue } from 'vue-class-component';
import { ListViewModel } from '@/components/content/ListViewModel';
import { reactive } from 'vue';

@Options({
    components: {
        Spinner,
    },
})
export default class ContentList extends Vue {
    @Provide() listVM = new ListViewModel();

    private showGroups: { [key: string]: boolean } = { '': true };

    created() {
        this.listVM.init();
    }

    toggleGroupExpand(group: string) {
        // @todo [Vue3] This function can be removed and this.showGroups changed directly.
        this.showGroups[group] = !this.showGroups[group];
        this.$forceUpdate();
    }
}
